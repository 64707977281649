import React, { useContext, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import DlgDelete from '../../common/dialog/DlgDelete';
import DlgPaiementEdit from '../../common/dialog/DlgPaiementEdit';
import { Invoice, InvoiceItem } from '../../common/models/invoice';
import { Pension } from '../../common/models/pension';
import { dataService } from '../../services/Services';
import { CreateContract } from '../../utils/GenContractPdf';
import { CreateInvoice } from '../../utils/GenFacturePdf';
import { getDateStr2FromTimeStamp, round2Digit } from '../../utils/Tools';
import { ConfigContextType } from '../../common/models/config';
import {ConfigContext} from '../../context/ConfigContext';


export default function InvoiceDetail(props: any) {
    const [infoPension, setInfoPension] = useState(new Pension());
    const [invoiceInfo, setInvoiceInfo] = useState(new Invoice());
    const [totalPension, setTotalPension] = useState(0);
    const [invoicePension, setInvoicePension] = useState<InvoiceItem[]>([]);
    const [totalAutre, setTotalAutre] = useState(0);
    const [invoiceAutre, setInvoiceAutre] = useState<InvoiceItem[]>([]);
    const [modeVue, setModeVue] = useState("display");    
    const { config } = useContext(ConfigContext) as ConfigContextType;
    let history = useNavigate();
    const params = useParams()

    React.useEffect(() => {

        dataService.Configuration.getInfoPension().then((response) => { setInfoPension(response.data) });
        loadData();

    }, [params]);


    const loadData = async () => {
        if (params.id !== undefined) {
            dataService.Invoices.getInvoice(parseInt(params.id)).then((response) => { computeInfo(response.data) });
        }
    }

    const computeInfo = async (invoice: Invoice) => {

        setInvoiceInfo(invoice);
        let totalPensionTmp = 0;
        let totalAutreTmp = 0;
        let invoicePensionTmp = new Array<InvoiceItem>();
        let invoiceAutreTmp = new Array<InvoiceItem>();
        invoice.ListDetail.forEach(element => {
            if (element.Type === 0) {
                totalPensionTmp += Math.round(element.MontantU * element.Nombre * (1 - element.Remise / 100) * (1 + element.Tva / 100) * 100) / 100;
                invoicePensionTmp.push(element);
            } else if (element.Type === 1) {
                totalAutreTmp += Math.round(element.MontantU * element.Nombre * (1 - element.Remise / 100) * (1 + element.Tva / 100) * 100) / 100;
                invoiceAutreTmp.push(element);
            }
        });
        setTotalPension(totalPensionTmp);
        setInvoicePension(invoicePensionTmp);
        setTotalAutre(totalAutreTmp);
        setInvoiceAutre(invoiceAutreTmp);


    }

    const callPaiement = async () => {
        setModeVue("editpaiement");
    }
    const openDlgDelete = async () => {
        setModeVue("delete");
    }

    const onCloseDlg = async () => {
        setModeVue("display");
    }

    const onValid = async (invoice: Invoice) => {
        dataService.Invoices.saveReglement(invoice.Id, invoice.PaiementDate, invoice.PaiementType,invoice.PaiementMail).then((response) => { onCloseDlg(); loadData(); });
    }

    const onDelete = async () => {
        
        dataService.Invoices.deleteInvoice(invoiceInfo.Id).then((response) => { onCloseDlg(); history('/invoice'); });
    }

    return (
        <div className="invoice-detail"  >
            <div>
                <div className="filter-header d-flex justify-content-between">
                    <div className="filter-header d-flex justify-content-start">
                        <div className="btn btn-outline-danger margright" onClick={openDlgDelete}>Supprimer</div>
                    </div>
                    <div className="filter-header d-flex justify-content-end">
                        <NavLink to={'/invoice'}>
                            <div className="btn btn-outline-primary margright" >Retour</div>
                        </NavLink>
                        <div className="btn btn-outline-primary margright " onClick={callPaiement}>Réglement</div>
                        <NavLink to={'/invoice-edit/' + invoiceInfo.Id}>
                            <div className="btn btn-outline-primary margright ">Editer</div>
                        </NavLink>
                        <CreateContract invoiceInfo={invoiceInfo} infoPension={infoPension} />
                        <CreateInvoice invoiceInfo={invoiceInfo} infoPension={infoPension} />
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Client</h5>
                                <div className='infoProprio'>
                                    <div className="blockEntete">
                                        <div className="fw-bold">Facturer à</div>
                                        <div>{invoiceInfo.NomProprietaire}</div>
                                    </div>
                                    <div className="blockEntete">
                                        <div className="fw-bold">Adresse</div>
                                        <div>{invoiceInfo.Adresse}</div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Statut</h5>
                                <div className='infoContact'>
                                <div className="row" >
                                        <div className="col-6">
                                    <div className="blockEntete">
                                        <div className="fw-bold">Statut</div>
                                        {invoiceInfo.Statut === 0 && <div >Non réglée</div>}
                                        {invoiceInfo.Statut === 1 && <div >Réglée</div>}
                                    </div>
                                    </div>
                                    <div className="col-6">
                                       {config.PensionId===1 && <div className="blockEntete">
                                            <div className="fw-bold">Récapitulatif par mail</div>
                                            {invoiceInfo.PaiementMail === 0 && <div >Non envoyé</div>}
                                            {invoiceInfo.PaiementMail === 1 && <div >Envoyé</div>}
                                        </div>}
                                    </div>
                                    </div>
                                    {invoiceInfo.Statut === 1 && <div className="row" >
                                        <div className="col-6">
                                            <div className="blockEntete">
                                                <div className="fw-bold">Date paiement</div>
                                                <div>{getDateStr2FromTimeStamp(invoiceInfo.PaiementDate)}</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="blockEntete">
                                                <div className="fw-bold">Type de paiement</div>
                                                {invoiceInfo.PaiementType === 2 && <div >Chèque</div>}
                                                {invoiceInfo.PaiementType === 3 && <div >Carte bleue</div>}
                                                {invoiceInfo.PaiementType === 4 && <div >Espèces</div>}
                                                {invoiceInfo.PaiementType === 5 && <div >Virement</div>}
                                                {invoiceInfo.PaiementType === 6 && <div >Paypal</div>}
                                                {invoiceInfo.PaiementType === 1 && <div >Autres</div>}

                                            </div>
                                        </div >
                                    </div >}

                                </div >
                            </div >
                        </div >
                    </div >
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Facture</h5>

                                <div className='infoFacture'>

                                    <div className="blockEntete">
                                        <div className="fw-bold">Facture N°</div>
                                        <div>{invoiceInfo.Numero}</div>
                                    </div>
                                    <div className="blockEntete">
                                        <div className="fw-bold">Date</div>
                                        <div>{getDateStr2FromTimeStamp(invoiceInfo.Date)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            {totalPension > 0 && <table className="table " >
                                <tbody>
                                    <tr className='entete'>
                                        <td className='col-libelle'>Libelle</td>
                                        <td className='col-type'>Type</td>
                                        <td className='col-nombre'>Qté</td>
                                        <td className='col-prixU'>Prix U.</td>
                                        <td className='col-remise'>Remise</td>
                                        <td className='col-tva'>TVA</td>
                                        <td className='col-total'>Total</td>
                                    </tr>
                                    {invoicePension && invoicePension.map((invoiceDetail, index) =>
                                        <tr key={index} >
                                            <td>{invoiceDetail.Libelle}</td>
                                            <td className=''>Pension</td>
                                            <td className='isdecal'>{invoiceDetail.Nombre}</td>
                                            <td className='isdecal'>{round2Digit(invoiceDetail.MontantU)}</td>
                                            <td className='isdecal'>{invoiceDetail.Remise}%</td>
                                            <td className='isdecal'>{invoiceDetail.Tva === 0 ? '-' : invoiceDetail.Tva}</td>
                                            <td>{round2Digit((invoiceDetail.MontantU * invoiceDetail.Nombre) * (1 - invoiceDetail.Remise / 100) * (1 + invoiceDetail.Tva / 100))}</td>
                                        </tr>)}
                                    <tr className='lineSepTop'>
                                        <td className='notBottom' colSpan={4}></td>
                                        <td className="fw-bold ">Montant</td>
                                        <td></td>
                                        <td>{round2Digit(totalPension)}</td>
                                    </tr>
                                    <tr>
                                        <td className='notBottom notTop' colSpan={4}></td>
                                        <td>Remise pension </td>
                                        <td className='isdecal'>{invoiceInfo.Remise}%</td>
                                        <td>-{round2Digit(totalPension * invoiceInfo.Remise / 100)}</td>
                                    </tr>
                                    <tr>
                                        <td className='notBottom notTop' colSpan={4}></td>
                                        <td className="fw-bold ">Montant pension</td>
                                        <td></td>
                                        <td className="fw-bold ">{round2Digit(totalPension - totalPension * invoiceInfo.Remise / 100)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>}
                            {totalAutre > 0 && <table className="table " >
                                <tbody>
                                    {totalPension > 0 && <tr className='entete'>
                                        <td className='col-libelle'>Autres prestations</td>
                                        <td className='col-type'></td>
                                        <td className='col-nombre'></td>
                                        <td className='col-prixU'></td>
                                        <td className='col-remise'></td>
                                        <td className='col-tva'></td>
                                        <td className='col-total'></td>
                                    </tr>}
                                    {totalPension === 0 &&
                                        <tr className='entete' >
                                            <td className='col-libelle'>Libelle</td>
                                            <td className='col-type'>Type</td>
                                            <td className='col-nombre'>Qté</td>
                                            <td className='col-prixU'>Prix U.</td>
                                            <td className='col-remise'>Remise</td>
                                            <td className='col-tva'>TVA</td>
                                            <td className='col-total'>Total</td>
                                        </tr>}
                                    {invoiceAutre && invoiceAutre.map((invoiceDetail, index) =>
                                        <tr key={index}  >
                                            <td>{invoiceDetail.Libelle}</td>
                                            <td className=''>Autre</td>
                                            <td className='isdecal'>{invoiceDetail.Nombre}</td>
                                            <td className='isdecal'>{round2Digit(invoiceDetail.MontantU)}</td>
                                            <td className='isdecal'>{invoiceDetail.Remise}%</td>
                                            <td className='isdecal'>{invoiceDetail.Tva === 0 ? '-' : invoiceDetail.Tva}</td>
                                            <td>{round2Digit(invoiceDetail.MontantU * invoiceDetail.Nombre * (1 - invoiceDetail.Remise / 100) * (1 + invoiceDetail.Tva / 100))}</td>
                                        </tr >)}
                                    <tr className='lineSepTop'>
                                        <td className='notBottom' colSpan={4}></td>
                                        <td className="fw-bold ">Montant autres </td>
                                        <td></td>
                                        <td>{round2Digit(totalAutre)}</td>
                                    </tr>

                                </tbody >
                            </table >}

                            <table className="table ">
                                <tbody>
                                    <tr className='lineSepTop'>
                                        <td className='col-libelle notBottom notTop'></td>
                                        <td className='col-type notBottom notTop'></td>
                                        <td className='col-nombre notBottom notTop'></td>
                                        <td className='col-prixU notBottom notTop'></td>
                                        <td className='col-remise'></td>
                                        <td className='col-tva'></td>
                                        <td className='col-total'></td>
                                    </tr>
                                    <tr>
                                        <td className='notBottom notTop' colSpan={4}></td>
                                        <td className="fw-bold ">Montant total Net</td>
                                        <td></td>
                                        <td className="fw-bold ">{round2Digit(totalPension - totalPension * invoiceInfo.Remise / 100 + totalAutre)}</td>
                                    </tr>
                                    <tr>
                                        <td className='notBottom notTop' colSpan={4}></td>
                                        <td colSpan={2}>
                                            <div className="fw-bold d-flex ">
                                                <div>Acompte&nbsp; </div>
                                                {invoiceInfo.AcompteType > 0 && <div >
                                                    (
                                                    {invoiceInfo.AcompteType === 2 && <span>Chèque</span>}
                                                    {invoiceInfo.AcompteType === 3 && <span >Carte bleue</span>}
                                                    {invoiceInfo.AcompteType === 4 && <span >Espèces</span>}
                                                    {invoiceInfo.AcompteType === 5 && <span >Virement</span>}
                                                    {invoiceInfo.AcompteType === 6 && <span >Paypal</span>}
                                                    {invoiceInfo.AcompteType === 1 && <span  > Autres</span >})
                                                </div >}
                                            </div >
                                        </td >

                                        <td>-{round2Digit(invoiceInfo.Acompte)}</td>
                                    </tr >
                                    <tr>
                                        <td className='notBottom notTop' colSpan={4}></td>
                                        <td  >

                                            <div>Debours</div>

                                        </td>
                                        <td></td>
                                        <td>{round2Digit(invoiceInfo.Debours)}</td>
                                    </tr>
                                    <tr>
                                        <td className='notBottom notTop' colSpan={4}></td>
                                        <td className="fw-bold backcolored">Total Net à payer</td>
                                        <td className="fw-bold backcolored"></td>
                                        <td className="fw-bold backcolored">{round2Digit(totalPension - totalPension *
                                            invoiceInfo.Remise / 100 +
                                            totalAutre - invoiceInfo.Acompte + invoiceInfo.Debours)}</td>
                                    </tr>
                                </tbody >
                            </table >
                        </div >
                    </div >
                </div >
            </div >

            {modeVue === "editpaiement" && <DlgPaiementEdit onClose={onCloseDlg} invoice={invoiceInfo} onValid={onValid} />}
            {modeVue === "delete" && <DlgDelete onClose={onCloseDlg} libelle={'Facture n° ' + invoiceInfo.Numero} onDelete={onDelete} />}

        </div >
    )
}
