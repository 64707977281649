import { Pet } from "./pet";

export class Invoice {
    Id: number=0;
    Numero :number=0;
    IdProprietaire : number=0;
    NomProprietaire : string="";
    Telephone : string="";
    Email : string="";
    Adresse :string="";
    Statut: number=0;
    Date :number=0;
    Remise : number=0;
    Debours : number=0;
    AcompteType : number=0;
    PaiementDate:number=0;
    PaiementType : number=0;
    PaiementMail: number=0;
    Acompte : number=0;
    Taxe : number=0;
    Montant:number=0;
    DateDeb : number=0;
    DateFin : number=0;
    Duree:number=0;
    NbJour:number=0;
    NbDemi:number=0;
    ListDetail: InvoiceItem[]=[];
    ListPets : Pet[]=[];
    IsVisible : boolean=false;
    
}
export class InvoiceItem {
    Id: number=0;
    InvoiceId :number=0;
    Libelle : string="";
    Type: number=0;//0: pension , 1 : autre
    MontantU :number=0;
    Nombre : number=0;
    PeriodeId : number=0;
    Remise : number=0;
    TarifId : number=0;
    Tva : number=0;
    IsDemi : boolean=false;
    TypeAnimal :number=0;
}


export class InvoiceInfoTva {
    Taux : number=0;
    BaseHT:number=0;
}
