import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dataService } from "../../services/Services";
import DatePicker from "react-datepicker";
import { extractDateFromTimeStamp, getDateFromTimeStamp } from "../../utils/Tools";
import { Pet } from "../../common/models/pet";
import { TypePet } from "../../common/models/type-pet";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { ConfigContextType } from "../../common/models/config";
import { ConfigContext } from "../../context/ConfigContext";
import { Box } from "../../common/models/box";
import { Tarification } from "../../common/models/tarification";
import { Periode } from "../../common/models/periode";
import TimePicker from "../../components/TimePicker";
import Select from "react-select";
import PeriodeEditComposant from "./PeriodeEditComposant";


export default function PeriodeEditPage(props: any) {

  let navigate = useNavigate();
  const params = useParams();

  const [periodeInfo, setPeriodeInfo] = useState<Periode>(new Periode());

  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {

    loadData();

  }, [params]);

  const loadData = async () => {

    let id = 0;
    let type = 0;
    let date = 0;
    let box = 0;

    if (params.id !== undefined)
      id = parseInt(params.id);
    if (params.type !== undefined)
      type = parseInt(params.type);
    if (params.date !== undefined)
      date = parseInt(params.date);
    if (params.box !== undefined)
      box = parseInt(params.box);

    if (id > 0) {
      dataService.Periodes.getPeriode(id).then((response) => { setPeriodeInfo(response.data);setIsLoad(true); });
    } else {
      newPeriode(type, box, date);
    }
  }

  const newPeriode = async (type: number, box: number, date: number) => {
    let newPeriode = new Periode();

    newPeriode.Id = 0;
    newPeriode.TypeAnimal = type;
    newPeriode.IdBox = box;
    var day = new Date(date * 1000);

    newPeriode.DateDeb = (new Date(day.getFullYear(), day.getMonth(), day.getDate(), 8, 0, 0)).getTime() / 1000;
    newPeriode.DateFin = (new Date(day.getFullYear(), day.getMonth(), day.getDate(), 18, 0, 0)).getTime() / 1000;
    newPeriode.Montant = 0;
    newPeriode.Acompte = 0;
    setPeriodeInfo(newPeriode);
    setIsLoad(true);

  }


  const onClose = async () => {
    navigate(-1);
  };


  if (isLoad)
    return (
      <PeriodeEditComposant onClose={onClose} periode={periodeInfo} onUpdate={onClose} />
    );
  else
    return (<div>Chargement...</div>);
}