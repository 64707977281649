import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dataService } from "../../services/Services";
import DatePicker from "react-datepicker";
import { extractDateFromTimeStamp, getDateFromTimeStamp } from "../../utils/Tools";
import { Pet } from "../../common/models/pet";
import { TypePet } from "../../common/models/type-pet";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { ConfigContextType } from "../../common/models/config";
import { ConfigContext } from "../../context/ConfigContext";
import { Box } from "../../common/models/box";
import { Tarification } from "../../common/models/tarification";
import { Periode } from "../../common/models/periode";
import TimePicker from "../../components/TimePicker";
import Select from "react-select";
import DlgDelete from "../../common/dialog/DlgDelete";
import { PlanningEvent } from "../../common/models/planning-event";


export default function EventEditComposant(props: { onClose: any, planningEvent: PlanningEvent, onUpdate: any }) {

  let navigate = useNavigate();
  const params = useParams();

  const { config } = useContext(ConfigContext) as ConfigContextType;

  const [modeVue, setModeVue] = useState("display");
  const [eventInfo, setEventInfo] = useState<PlanningEvent>(new PlanningEvent());
  const { reset, register, setValue, handleSubmit } = useForm();
  const [selectedDateDeb, setSelectedDateDeb] = useState(new Date());
  const [selectedDateFin, setSelectedDateFin] = useState(new Date());
  const [selectedTimeDeb, setSelectedTimeDeb] = useState(0);
  const [selectedTimeFin, setSelectedTimeFin] = useState(0);
  const [messageError, setMessageError] = useState<String[]>([]);
  const [refreshId, setRefreshId] = useState(0);



  useEffect(() => {

    computeInfo(props.planningEvent);

  }, [props.planningEvent]);


  useEffect(() => {

    reset(eventInfo);

  }, [eventInfo]);


  const computeInfo = async (planningEvent: PlanningEvent) => {

    var dateDebTs = extractDateFromTimeStamp(planningEvent.DateDeb);
    var dateFinTs = extractDateFromTimeStamp(planningEvent.DateFin);
    setSelectedDateDeb(getDateFromTimeStamp(dateDebTs));
    setSelectedDateFin(getDateFromTimeStamp(dateFinTs));
    setSelectedTimeDeb(planningEvent.DateDeb - dateDebTs);
    setSelectedTimeFin(planningEvent.DateFin - dateFinTs);
    setEventInfo(planningEvent);

  }

  const setDateDeb = (newDate: any) => {
    if (newDate !== null) {
      setSelectedDateDeb(newDate);
    }
  }

  const setDateFin = (newDate: any) => {
    if (newDate !== null) {
      setSelectedDateFin(newDate);
    }
  }

  const updateTimeDeb = async (newTime: number) => {
    setSelectedTimeDeb(newTime);
  };

  const updateTimeFin = async (newTime: number) => {
    setSelectedTimeFin(newTime);
  };

  const onClose = async () => {
    props.onClose();

  };
  const openDelete = async () => {
    setModeVue("delete");
  };

  const closeDelete = async () => {
    setModeVue("display");
  };
  const onDelete = async () => {
    dataService.Periodes.deleteEvent(eventInfo.Id).then((response) => { props.onUpdate(); });
  };


  const onSubmit = (data: any) => {
    setMessageError([]);
    var event = eventInfo;
    event.Description= data.Description;
    event.TypeEvent= data.TypeEvent;
    event.DateDeb = (selectedDateDeb.getTime()) / 1000 + selectedTimeDeb;
    event.DateFin = (selectedDateFin.getTime()) / 1000 + selectedTimeFin;

    if (event.Description !== undefined && event.Description !=="" &&
      !isNaN(event.DateDeb) && !isNaN(event.DateFin) &&
      (event.DateDeb < event.DateFin)) {
     
      dataService.Periodes.saveEvent(event).then((response) => { props.onUpdate(); });

    } else {
      let message = new Array<string>();
      message.push("Veuillez remplir correctement tous les champs :");
       if (isNaN(event.DateDeb) || isNaN(event.DateFin))
        message.push(" - date incorrect");
      if (event.DateDeb >= event.DateFin)
        message.push(" - date de fin doit être supérieure à la date de début");

      setMessageError(message);
    }

  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid margtop"  >
          <h4>{eventInfo.Id > 0 ? "Modification évènement" : "Ajout évènement"}</h4>
          <div className="">
            <div className="form-group ">
              <label>Type</label>
              <select className="form-control" {...register("TypeEvent", { required: false })} >
                <option  value={1}>Autre</option>
                <option  value={2}>Fermeture</option>
              </select>
            </div>
            <div className="form-group ">
              <div className="row">
              <div className="col-12">
                  <div className='line-datetime-picker' >
                    <div className="div-datetime">
                    <div className="libelle">Date de début</div>
                  <DatePicker selected={selectedDateDeb} className="form-control" onChange={(date) => setDateDeb(date)} dateFormat="dd/MM/yyyy" />
                </div>
                
                  <TimePicker date={selectedTimeDeb} onTimeChange={updateTimeDeb} />
                </div>
                </div>
              </div>
            </div >
            <div className="form-group ">
              <div className="row">
              <div className="col-12">
                  <div className='line-datetime-picker' >
                    <div className="div-datetime">
                    <div className="libelle">Date de fin</div>
                  <DatePicker selected={selectedDateFin} className="form-control" onChange={(date) => setDateFin(date)} dateFormat="dd/MM/yyyy" />
                </div>
                  <TimePicker date={selectedTimeFin} onTimeChange={updateTimeFin} />
                  </div>
                </div>
              </div>
            </div >
           
            <div className="form-group "  >
              <label >Description</label>
              <div>
                <input placeholder="Description" type="text" className="form-control" {...register("Description", { required: true })} />
              </div>
            </div >
            <div className="ligne">
              <div className="text-danger">
                {messageError && messageError.map((message, index) => <div key={index} > {message} </div>)}
              </div>
            </div>
          </div >
          <div className="d-flex justify-content-end margtop margbottom">
            <div className="btn btn-outline-danger margright" onClick={() => openDelete()}>Supprimer</div>
            <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
            <div className="btn btn-outline-secondary margright" onClick={() => onClose()} >Annuler</div>
          </div>
        </div>

      </form>
      {modeVue === "delete" && <DlgDelete onClose={closeDelete} libelle={'l\'évènement ' + eventInfo.Description} onDelete={onDelete} />}
    </div>
  );
}