import {  useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Vaccine } from "../models/vaccine";
import { getDateFromTimeStamp } from "../../utils/Tools";
import DatePicker from "react-datepicker";
import { PetVaccine } from "../models/pet-vaccine";
import { dataService } from "../../services/Services";


export default function DlgPetVaccin(props: { petType: number, vaccineInfo: PetVaccine, onClose: any, onValid: any }) {


    const [listVaccine, setListVaccine] = useState<Vaccine[]>([]);
    const { register, setValue, handleSubmit } = useForm();

    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
       
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.vaccineInfo]);

    const loadData = async () => {
        
        var restPet = await dataService.Pets.getListVaccine(props.petType);
        setListVaccine(restPet.data);
        setValue("IdVaccin", props.vaccineInfo.IdVaccin);
        setSelectedDate(getDateFromTimeStamp(props.vaccineInfo.DateRealisation));

    }

    const setDate = (newDate: any) => {
        if (newDate != null) {
            setSelectedDate(newDate);
        }
    }

    const onSubmit = (data: any) => {

        var vaccine = data;
        vaccine.Id = props.vaccineInfo.Id;
        vaccine.IdAnimal = props.vaccineInfo.IdAnimal;
        vaccine.DateRealisation =   (selectedDate.getTime()) / 1000 ;
        props.onValid(vaccine);
    }

    const onCloseDlg = async () => {
        props.onClose();
    }





    return (
        <Dialog
            title="Information vaccin"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                      <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Fermer</div>
                </div>
            }>

            <div className="dlg_pet_vaccin">

                <div className="form-group row">
                    <label className="col-4 col-form-label">Vaccin</label>
                    <div className="col-8">
                        <select className="form-control" {...register("IdVaccin", { required: false })} defaultValue={props.vaccineInfo.IdVaccin}>
                            {listVaccine && listVaccine.map((b) => <option key={b.Id} value={b.Id}>{b.Libelle}</option>)}
                        </select>


                    </div>

                </div>
                <div className="form-group row">
                    <label className="col-4 col-form-label centerItem">Date de réalisation</label>
                    <div className="col-8">
                        <DatePicker selected={selectedDate} className="form-control" onChange={(selectdate) => setDate(selectdate)} dateFormat="dd/MM/yyyy" />

                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-4 col-form-label centerItem">Validité (en mois) </label>
                    <div className="col-8">
                        <input type='number' className="form-control" {...register("DureeValid", { required: false })} defaultValue={props.vaccineInfo.DureeValid} />
                    </div>

                </div >
                <div className="ligne">
                    <div id="editEtatmessageError" className="text-danger"></div>
                </div>
            </div>
        </Dialog >
    );
}
