import React, { useState } from 'react';
import { Periode } from '../../common/models/periode';
import { dataService } from '../../services/Services';
import { getDateStr2FromTimeStamp } from '../../utils/Tools';
import { Tarification } from '../../common/models/tarification';
import InvoiceEditServiceItem from './InvoiceEditServiceItem';
import { InvoiceItem } from '../../common/models/invoice';

export default function InvoiceEditService(props: { listService: InvoiceItem[], proprioId: number, listServiveUpdated : any }) {

    const { listService, proprioId } = props;

    const [listDetail, setListDetail] = useState<InvoiceItem[]>([]);
    const [nbPeriodeWait, setNbPeriodeWait] = useState(0);
    const [listTarif, setListTarif] = useState<Tarification[]>([]);
    const [listPeriodeWait, setListPeriodeWait] = useState<Periode[]>([]);
    const [refreshId, setRefreshId] = useState(0);
    React.useEffect(() => {
        loadData();
    }, [props.listService,props.proprioId]);


    const loadData = async () => {

        var resttarif = await dataService.Tarifications.listTarification(-2);
        var restperiodeInvoice = await dataService.Periodes.listPeriodeInvoice(proprioId);

        setListDetail(listService);
        setListTarif(resttarif.data);
        onloadPeriodeDispo(restperiodeInvoice.data);
        refreshListDetail( listService);
    }


    const onloadPeriodeDispo = async (listPeriode: Periode[]) => {
        listPeriode.forEach((value, index) => {
            value.IsSelected=listService.find(x=>x.PeriodeId===value.Id)!==undefined;
        });

        setListPeriodeWait(listPeriode);
        setNbPeriodeWait(listPeriode.filter(x=>!x.IsSelected).length);

    }

    const addItem = async () => {

        var listTarifForNewElement = listTarif.find(x=>x.Categorie===-1);
        let invoiceItemTmp = new InvoiceItem();
        invoiceItemTmp.Libelle = listTarifForNewElement?listTarifForNewElement.Libelle:"";
        invoiceItemTmp.Nombre = 1;
        invoiceItemTmp.MontantU = listTarifForNewElement?listTarifForNewElement.Montant:0;
        invoiceItemTmp.Remise = 0;
        invoiceItemTmp.PeriodeId = 0;
        invoiceItemTmp.IsDemi = false;
        invoiceItemTmp.Type = 1;
        invoiceItemTmp.TypeAnimal = -1;
        invoiceItemTmp.TarifId=listTarifForNewElement?listTarifForNewElement.Id:0;
        invoiceItemTmp.Id = -(listDetail.length) - 1;

        refreshListDetail( [...listDetail, invoiceItemTmp]);
    }


    const removeItem = async (id: number,periodeId:number) => {
      
       let element = listDetail.find(x=>x.Id===id);
       if(element!==undefined)
        refreshListDetail(listDetail.filter(item => element?.Id !== item.Id && element?.PeriodeId!==item.PeriodeId ));
      
    }

    const addPeriode = async () => {
        var listDetailToAdd= listDetail; 
       var listDetailToAddnewId =  Math.min(...listDetail.map(o => o.Id));
       if(listDetailToAddnewId>0)
       listDetailToAddnewId=0;

        listPeriodeWait.forEach(element => {
           
            var selectTarif = listTarif.find(x => x.Id === element.TarifId);
            if (element.IsSelected === undefined || element.IsSelected === false) {
                let invoiceTmp = new InvoiceItem();
                invoiceTmp.IsDemi = false;
                invoiceTmp.Libelle = element.Nom + " du " + getDateStr2FromTimeStamp(element.DateDeb) + " au " + getDateStr2FromTimeStamp(element.DateFin);
                invoiceTmp.Nombre = element.NbJour;
                invoiceTmp.PeriodeId = element.Id;
                invoiceTmp.MontantU = selectTarif ? selectTarif.Montant : 0;
                invoiceTmp.TypeAnimal = element.TypeAnimal;
                invoiceTmp.Remise = 0;
                invoiceTmp.Type = 0;
                invoiceTmp.TarifId = element.TarifId;
                invoiceTmp.Id = --listDetailToAddnewId;
                invoiceTmp.Tva = selectTarif ? selectTarif.Tva : 0
                element.IsSelected = true;
                listDetailToAdd= [...listDetailToAdd, invoiceTmp];

                if (element.NbDemi > 0) {
                    let invoiceTmpDemi = new InvoiceItem();
                    invoiceTmpDemi.IsDemi = true;
                    invoiceTmpDemi.Libelle = element.Nom + " du " + getDateStr2FromTimeStamp(element.DateDeb) + " au " + getDateStr2FromTimeStamp(element.DateFin) + " (Demi journée)";
                    invoiceTmpDemi.Nombre = element.NbDemi;
                    invoiceTmpDemi.PeriodeId = element.Id;
                    invoiceTmpDemi.MontantU = selectTarif ? selectTarif.MontantDemi : 0;
                    invoiceTmpDemi.TypeAnimal = element.TypeAnimal;
                    invoiceTmpDemi.Remise = 0;
                    invoiceTmpDemi.Type = 0;
                    invoiceTmpDemi.TarifId = element.TarifId;
                    invoiceTmp.Tva = selectTarif ? selectTarif.Tva : 0
                    invoiceTmpDemi.Id = --listDetailToAddnewId;
                    listDetailToAdd=[...listDetailToAdd, invoiceTmpDemi];
                }
            }
        });

       
        setNbPeriodeWait(0);
        refreshListDetail(listDetailToAdd);
    }
    
    const onUpdateInfo = async (invoiceItem: InvoiceItem, newTarif: Tarification) => {

        listDetail.forEach((value, index) => {
            if (value.Id === invoiceItem.Id) {
                value = invoiceItem;
            }
        }); 
        if (invoiceItem.PeriodeId > 0) {
           
            var findDemi = listDetail.find(x => x.PeriodeId === invoiceItem.PeriodeId && x.IsDemi === true);         
            if (findDemi) {
                findDemi.Remise = invoiceItem.Remise;
                if (newTarif) {
                    findDemi.TarifId = newTarif.Id;
                    findDemi.MontantU = newTarif.MontantDemi;
                    findDemi.Tva = newTarif.Tva;
                }
            }
        }
        refreshListDetail(listDetail);
  
    }

    const refreshListDetail = async (listUpdated :InvoiceItem[]) => {
        setListDetail(listUpdated);
        setRefreshId(refreshId + 1)
        props.listServiveUpdated(listUpdated);
    }

    return (

        <div className="card">
            <div className="card-body">
                <div className="card-title">
                    <div className="d-flex justify-content-between">
                        <h5>Services</h5>
                        <div className="d-flex">
                            {nbPeriodeWait > 0 && <div className="btn btn-outline-primary margright"
                                onClick={addPeriode}>Ajouter {nbPeriodeWait} periode(s) non facturée(s)
                            </div>}

                            <div className="btn btn-outline-primary" onClick={addItem}>
                                 <i className="fa-solid fa-add"/>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-bordered table-hover">
                    <tbody>
                        <tr className='entete'>
                            <td className='col-type'>Type</td>
                            <td className='col-tarif'>Tarif</td>
                            <td className='col-libelle'>Libelle</td>
                            <td className='col-nombre'>Qté</td>
                            <td className='col-prixU'>Prix U.</td>
                            <td className='col-remise'>% Remise </td>
                            <td className='col-tva'>TVA </td>
                            <td className='col-total'>Total</td>
                            <td className='col-action'></td>
                        </tr>

                        {listDetail && listDetail.map((invoiceDetail, index) =>

                            <InvoiceEditServiceItem key={index} invoiceDetailItem={invoiceDetail} listTarif={listTarif} onRemove={removeItem} onUpdateInfo={onUpdateInfo} />

                        )}

                    </tbody >
                </table >
            </div >
        </div >

    )
}
