import {  useEffect } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Pet } from "../models/pet";


export default function DlgInfoComplementaireEdit(props: { infoCompl: Pet, onClose: any, onValid: any }) {

   
    const { register,  handleSubmit } = useForm();
   
  
    useEffect(() => {
        if (props.infoCompl !== undefined) {
           
        }
    }, [props.infoCompl]);



    const onSubmit = (data: any) => {
        
        var pet = data;
        pet.Id = props.infoCompl.Id;
        props.onValid(pet);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    return (
        <Dialog
            title="Informations complémentaires"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>

            <div className="dlg_info_compl_edit">

            <div className="form-group row">
          <label className="col-3 col-form-label">De la part du propriétaire</label>
          <div className="col-9">
            <textarea placeholder="Indication donnée par le propriétaire sur l'animal" 
              className="form-control note-editor" rows={6} {...register("InfoProprio", { required: false })} defaultValue={props.infoCompl.InfoProprio} /> 
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Constaté en pension</label>
          <div className="col-9">
            <textarea placeholder="Indication sur l'animal constatée à la pension" 
              className="form-control note-editor" rows={6} {...register("InfoPension", { required: false })} defaultValue={props.infoCompl.InfoPension} /> 
          </div>
        </div>
            <div className="ligne">
                <div id="editEtatmessageError" className="text-danger"></div>
            </div>
        </div >

        </Dialog >
    );
}
