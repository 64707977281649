import React, { useState } from 'react';
import {  InvoiceItem } from '../../common/models/invoice';
import {  round2Digit } from '../../utils/Tools';
import { Tarification } from '../../common/models/tarification';

export default function InvoiceEditServiceItem(props: { invoiceDetailItem: InvoiceItem, listTarif: Tarification[], onUpdateInfo: any, onRemove: any }) {


    const [invoiceDetail, setInvoiceDetail] = useState<null | InvoiceItem>(null);
    const [listTarifFilter, setListTarifFilter] = useState<Tarification[]>([]);
    const { listTarif, invoiceDetailItem } = props;

    React.useEffect(() => {
        if (listTarif && listTarif.length > 0 && invoiceDetailItem) {
            setListTarifFilter(listTarif.filter(x => (invoiceDetailItem.Type === 0 && x.Categorie === invoiceDetailItem.TypeAnimal) || 
                                                    (invoiceDetailItem.Type === 1 && x.Categorie === -1)));
            setInvoiceDetail(invoiceDetailItem);
        }
    }, [listTarif, invoiceDetailItem]);



    const updateTarification = async (event: any) => {
        
        if (invoiceDetail) {
          
            var selectTarif = props.listTarif.find(x => x.Id === Number(event.target.value));
            
            if (selectTarif !== undefined) {
                invoiceDetail.TarifId = selectTarif.Id;
                if (!invoiceDetail.IsDemi)
                    invoiceDetail.MontantU = selectTarif.Montant;
                else
                    invoiceDetail.MontantU = selectTarif.MontantDemi;
                invoiceDetail.Tva = selectTarif.Tva;
                if (invoiceDetail.Type === 1)
                    invoiceDetail.Libelle = selectTarif.Libelle;
                props.onUpdateInfo(invoiceDetail, selectTarif);
            }
        }
    }

    const updateNombre = async (event: any) => {
        if (invoiceDetail) {
            invoiceDetail.Nombre = Number(event.target.value);
            setInvoiceDetail(invoiceDetail);
            props.onUpdateInfo(invoiceDetail, null);
        }
    }

    const updateRemise = async (event: any) => {
        if (invoiceDetail) {
            invoiceDetail.Remise = Number(event.target.value);
            props.onUpdateInfo(invoiceDetail, null);
            setInvoiceDetail(invoiceDetail);
        }
    }

    const updateLibelle = async (event: any) => {
        if (invoiceDetail) {
            invoiceDetail.Libelle = event.target.value;
            props.onUpdateInfo(invoiceDetail, null);
            setInvoiceDetail(invoiceDetail);
        }
    }
    const removeItem = async () => {
        if (invoiceDetail) {
            props.onRemove(invoiceDetail.Id,invoiceDetail.PeriodeId);
        }
    }

    return (
        invoiceDetail &&
        <tr className='tr-edit' >
            <td>
                {!invoiceDetail.IsDemi && <div>
                    {invoiceDetail.PeriodeId > 0 && <div>Pension</div>}
                    {invoiceDetail.PeriodeId <= 0 && <div>Autre</div>}
                </div>
                }
            </td>
            <td>

                {!invoiceDetail.IsDemi && <div >
                    <select className="form-control" defaultValue={invoiceDetail.TarifId} onChange={(e) => updateTarification(e)}>

                        {listTarifFilter && listTarifFilter.map((tarif, index) =>
                            <option key={index} value={tarif.Id} >
                                {tarif.Libelle}
                                ({tarif.Montant}{tarif.MontantDemi > 0 ? ('/' + tarif.MontantDemi) : ''} €)
                            </option>
                        )}
                    </select>
                </div>}
            </td >
            <td>
                {invoiceDetail.PeriodeId === 0 && <input placeholder="" type="text" className="form-control" defaultValue={invoiceDetail.Libelle} onChange={(e) => updateLibelle(e)} />}
                {invoiceDetail.PeriodeId > 0 && <div >{invoiceDetail.Libelle}</div>}
            </td >
            <td>
                {invoiceDetail.PeriodeId === 0 && <input placeholder="" type="number" step="0.01" className="form-control" onChange={(e) => updateNombre(e)} defaultValue={invoiceDetail.Nombre} />}

                {invoiceDetail.PeriodeId > 0 && <div > {invoiceDetail.Nombre} </div>}
            </td >

            <td>{invoiceDetail.MontantU} </td>
            <td>
                {invoiceDetail.IsDemi === false && <div>
                    <input placeholder="" type="number" step="0.01" className="form-control" onChange={(e) => updateRemise(e)} defaultValue={invoiceDetail.Remise} />
                </div>}
                {invoiceDetail.IsDemi === true && <div >{invoiceDetail.Remise}</div >}
            </td >
            <td>{invoiceDetail.Tva === 0 ? '-' : invoiceDetail.Tva}</td>
            <td>{round2Digit(invoiceDetail.MontantU * invoiceDetail.Nombre * (1 - invoiceDetail.Remise / 100) * (1 + invoiceDetail.Tva / 100))}</td>
            <td className='item-action'>
                {!invoiceDetail.IsDemi && <div >
                    <div className="btn btn-outline-danger" onClick={() => removeItem()}> <i className="fa-solid fa-remove" /></div>
                </div >}
            </td >
        </tr >


    )
}
