import {  useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";


function DlgChangeState(props: { state:number,onClose:any, onValid: any }) {

    const {  handleSubmit } = useForm();
    const [libelle, setLibelle] = useState("");

    useEffect(() => {
       
        switch(props.state){
        case 1:
            setLibelle('Le devis a été envoyé ?');
            break;
          case 2:
            setLibelle('La période a été validée avec un devis ?');
            break;
          case 3:
            setLibelle('La période a été validée sans un devis ?');
            break;
        }
    }, [props.state]);


    const onCloseDlg = async() => {
      
        props.onClose();
    }
    const onSubmit = (data: any) => {

        props.onValid();
    }

    return (
        <Dialog
            title="Confirmation du changement de l'état"
           // open={openSetState}
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>
            <div className="dlg_periode_change_state">
                <label>{libelle}</label>

            </div>



        </Dialog >
    );
}
export default DlgChangeState;

