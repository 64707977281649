import React, { useEffect, useState } from 'react';
import { dataService } from '../../services/Services';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { convertMonthToString } from '../../utils/Tools';


export default function DashboardNbInOut(props: any) {
   // const labels = ['Janvier', '\nFévrier', 'Mars', '\nAvril', 'Mai', '\nJuin', 'Juillet', '\nAoût', 'Septembre', '\nOctobre', 'Novembre', '\nDécembre'];

    const [data, setData] = useState<any>([]);//new InOutData());
    const [listYear, setListYear] = useState<number[]>([]);
    const [selectedYear, setSelectedYear] = useState(0);

    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        var restMinMax = await dataService.Configuration.getMinMaxDateExploitation();


        var yearMin = new Date(restMinMax.data.MinDate * 1000).getFullYear();
        var yearMax = new Date(restMinMax.data.MaxDate * 1000).getFullYear();

        let listYear = new Array<number>();
        for (var i = yearMin; i <= (yearMax); i++)
            listYear.push(i);
        setListYear(listYear);

        var selectYear = 0;
        var today = new Date();
        if (yearMax >= today.getFullYear())
            selectYear = today.getFullYear();
        else
            selectYear = yearMax;


        loadGraph(selectYear);

    }

    const loadGraph = async (selectYear: number) => {
        
        var debut = new Date(selectYear, 0, 1).getTime() ;
        var fin = new Date(selectYear, 11, 31, 23, 59, 59).getTime() ;
        var restComparatif = await dataService.Statistique.getInOutForYear(debut, fin);

        var series = [];
        for (var j = 0; j < restComparatif.data.InData.length; j++) {
            series.push(
                {
                    name: convertMonthToString(j+1),
                    entrees: restComparatif.data.InData[j],
                    sorties: restComparatif.data.OutData[j]

                }
            )
        }
        setData(series);
        setSelectedYear(selectYear);

    }
    const onSelectedYearChange = (event: any) => {
        loadGraph(parseInt(event.target.value));
    }
  
    const CustomizedAxisTick=  (props: any) => {
        const { x, y, payload } = props;
     
        return (
          
          <g transform={`translate(${x},${y})`}>
            <text
           
              x={0}
              y={0}
              dy={16}
              textAnchor="end"
              fill="#666"
              transform="rotate(-20)"
            >
              { payload.value}
            </text>
          </g>
        );
      };

    return (
        <div className="card  b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Nombre entrées/sorties</h3>
                </div>
                <div className="card-body">
                    <div style={{ width: "100%", height: "300px" }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid  />
                                <XAxis  dataKey = 'name'   tick={<CustomizedAxisTick />}
                             interval={0}
                               />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar  dataKey="entrees" fill="#4DB6AC"  />
                                <Bar  dataKey="sorties" fill="#DB7464" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <hr className="d-print-none" />

                    <div className="card-action clearfix">
                        <select className="form-control" onChange={(event) => onSelectedYearChange(event)} value={selectedYear}>
                            {
                                listYear && listYear.map((year) =>
                                    <option key={year} value={year}>{year}</option>
                                )}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );

}

