import { useContext, useEffect, useState } from "react";
import { Periode } from "../../common/models/periode";
import { dataService } from "../../services/Services";
import ProprioPeriodeItem from "./ProprioPeriodeItem";
import { ConfigContext } from "../../context/ConfigContext";
import { ConfigContextType } from "../../common/models/config";
import DlgPeriodeEdit from "../../common/dialog/DlgPeriodeEdit";
import DlgChangeState from "../../common/dialog/DlgChangeState";

function ProprioPeriodeList(props: any) {

  const { config } = useContext(ConfigContext) as ConfigContextType;
  const [listPeriode, setListPeriode] = useState<Periode[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedPeriode, setSelectedPeriode] = useState<Periode>(new Periode());
  const [modeVuePeriode, setModeVuePeriode] = useState("display");



  useEffect(() => {
    loadPeriode();
  }, [props.proprioId]);



  const loadPeriode = () => {
    dataService.Periodes.listMoveProprio(props.proprioId).then((response) => { setListPeriode(response.data); });

  }
  const onCloseDlg = async () => {
    setModeVuePeriode("display");
  }

  const openDlgEdit = async (periode: Periode) => {
    setModeVuePeriode("editPeriode");
    setSelectedPeriode(periode);
  }

  const updatePeriode = async (periode: Periode) => {
    dataService.Periodes.savePeriode(periode).then((response) => { loadPeriode(); });
    onCloseDlg();
  }

  const deletePeriode = async (idperiode: number) => {
    dataService.Periodes.deletePeriode(idperiode).then((response) => { loadPeriode(); });
    onCloseDlg();
  }


  const openDlgState = async (periode: Periode, statut: number) => {
    setSelectedStatus(statut);
    setSelectedPeriode(periode);
    setModeVuePeriode("editState");
  }

  const updateState = async () => {
    dataService.Periodes.updatePeriodeState(selectedPeriode.Id, selectedStatus).then((response) => { loadPeriode(); });
    onCloseDlg();
  }
  return (
    <div className="card b">
      <div className="card-content ">
        <div className="card-header" >
          <h3>Périodes de pension</h3>
        </div>
        <div className="card-body  limit-height">
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <tbody>
                {
                  listPeriode != null && listPeriode.map((item) => (
                    <ProprioPeriodeItem key={item.Id} periodeItem={item} config={config} onEditPeriode={openDlgEdit} onEditState={openDlgState}></ProprioPeriodeItem>
                  ))
                }

              </tbody>
            </table>

          </div>
        </div>


      </div>

      {modeVuePeriode === "editPeriode" && <DlgPeriodeEdit onClose={onCloseDlg} periode={selectedPeriode} onValid={updatePeriode} onDelete={deletePeriode} />}
      {modeVuePeriode === "editState" && <DlgChangeState onClose={onCloseDlg} state={selectedStatus} onValid={updateState} />}

    </div >
  );
}
export default ProprioPeriodeList;