export class Periode {
    DateDeb: number=0;
    DateFin: number=0;
    DateDebPlan: number=0;
    DateFinPlan: number=0;
    HeureMouvement: string="";
    Id: number=0;
    IdAnimal: number=0;
    IdProprietaire: number=0;
    IdBox:number=0;
    Nom: string="";
    NomBox: string="";
    NomProprietaire: string="";
    TypeAnimal: number=0;
    TypeMouvement: number=0;
    Left : number=0;
    Width:number=0;
    LeftPercent : number=0;
    WidthPercent:number=0;
    Top :number=0;
    Line :number=0;
    NbJour : number=0;
    NbDemi : number=0;

    Color:string="";
    Duree : number=0;
   Etat:number=0;
   Position:number=0;
   HasTraitement:number=0;
   Traitement:string="";
   DateCreation :number=0;
   DateDevis :number=0;
   DateValidation:number=0;
   heureDeb: number=0;
    heureFin: number=0;

    Montant : number=0;
    MontantDemi : number=0;
    Acompte  : number=0;

    IsSelected : boolean=false;   
    TarifId :number=0; 

    
}

export class PeriodeStartEnd {
    DateDeb: number=0;
    DateFin: number=0;
}