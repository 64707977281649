import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { PlanningEvent } from '../../common/models/planning-event';
import { getDateStr2FromTimeStamp, getDateTimeStrFromTimeStamp, getTimeStrFromTimeStamp } from '../../utils/Tools';

export default function PlanningEventItem(props: { eventInfo: PlanningEvent, onEditEvent: any }) {
   
    const [tooltip, setTooltip] = useState("");
    const [color, setColor] = useState("");
    React.useEffect(() => {
        var infotootip = "";
        infotootip += props.eventInfo.Description + " <br/>";
        if(getDateStr2FromTimeStamp(props.eventInfo.DateDeb)===getDateStr2FromTimeStamp(props.eventInfo.DateFin)){
            infotootip += getDateTimeStrFromTimeStamp(props.eventInfo.DateDeb) + " à " + getTimeStrFromTimeStamp(props.eventInfo.DateFin);
        }
        else{
            infotootip += getDateTimeStrFromTimeStamp(props.eventInfo.DateDeb) + " au " + getDateTimeStrFromTimeStamp(props.eventInfo.DateFin);
        }
        setTooltip(infotootip);
        
    }, [props]);

    const openEditEvent = async () => {
        props.onEditEvent(props.eventInfo);
    }

    return (
        <div>
            <div className="planning_item " data-tip={tooltip} data-for={'tt_etat' + props.eventInfo.Id}
                onClick={openEditEvent}
                style={{ backgroundColor: props.eventInfo.Color, color: 'black', position: 'absolute', width: props.eventInfo.Width + 'px', height: '46px', left: props.eventInfo.Left + 'px', top: props.eventInfo.Top + 'px' }}>
                {props.eventInfo.Description}
                {/* <br />                {getDateTimeStrFromTimeStamp(props.eventInfo.DateDeb)} au {getDateTimeStrFromTimeStamp(props.eventInfo.DateFin)} */}
            </div>
            <ReactTooltip id={'tt_etat' + props.eventInfo.Id}
                html={true}
                effect='solid'
                place={'top'}
            /></div>

    );

}
