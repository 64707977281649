import React, { useState, useRef, forwardRef, useEffect } from "react";
import { getformatDate } from "../../utils/Tools";
import DatePicker from "react-datepicker";

function HomeHeader(props: any) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const inputref = useRef(null);

     useEffect(() => {
        var date = new Date(selectedDate);
        date.setHours(0, 0, 0, 0);
        setSelectedDate(date);

     }, []);

    const previousDate = () => {
        var date = new Date(selectedDate);
        date.setDate(date.getDate() - 1)
        setSelectedDate(date);
        props.onChangeDate(date);
    }

    const nextDate = () => {
        var date = new Date(selectedDate);
        date.setDate(date.getDate() + 1)
        setSelectedDate(date);
        props.onChangeDate(date);
    }
    const setDate = (newDate: any) => {
        if (newDate != null) {
            setSelectedDate(newDate);
            props.onChangeDate(newDate);
        }
    }

    return (
        <div className="d-flex justify-content-center">
            <a className="pointer" onClick={previousDate}><img src="/img/planimalin_previous.svg" alt="<" /></a>
            <div className="dateInfo d-flex margleft margright">
                <h3 className='h3Center'>Journée du  {getformatDate(selectedDate)}</h3>
                <div className="margleft h3Center">
                    <DatePicker
                        selected={selectedDate}
                        onChange={(date) => setDate(date)}
                        customInput={<CustomInput ref={inputref} />}
                    />
                </div>

            </div>
            <a className="pointer" onClick={nextDate}><img src="/img/planimalin_next.svg" alt=">" /></a>
        </div>
    );


};


const CustomInput = forwardRef((props: any, ref) => {
    return <div   {...props} ref={ref} >   <div className="btn btn-outline-secondary ">  <i className="fa-solid fa-calendar" /></div> </div>;
});

export default HomeHeader;

