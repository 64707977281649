import { useEffect, useState } from "react";
import { dataService } from "../../services/Services";
import PetVaccinItem from "./PetVaccinItem";
import { PetVaccine } from "../../common/models/pet-vaccine";
import DlgPetVaccin from "../../common/dialog/DlgPetVaccin";
import DlgDelete from "../../common/dialog/DlgDelete";
import { NavLink } from "react-router-dom";

export default function PetVaccinList(props: { petId: number, petType: number }) {

  const [listVaccine, setListVaccine] = useState<PetVaccine[]>([]);
  const [selectedVaccin, setSelectedVaccin] = useState(new PetVaccine());
  const [modeVue, setModeVue] = useState("display");


  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.petId]);


  const loadData = () => {
    dataService.Pets.getListVaccineForAnimal(props.petId).then((response) => { setListVaccine(response.data); });

  }
  const onCloseDlg = async () => {
    setModeVue("display");
  }

  const openDlgAdd = async () => {


    let newVaccin = new PetVaccine();
    newVaccin.Id = -1;
    newVaccin.DateRealisation = new Date().getTime() / 1000;
    newVaccin.DureeValid = 36;
    newVaccin.IdAnimal = props.petId;

    setSelectedVaccin(newVaccin);
    setModeVue("edit");
  }
  const openDlgEdit = async (petvaccine: PetVaccine) => {
    setSelectedVaccin(petvaccine);
    setModeVue("edit");
  }



  const openDlgDelete = async (petvaccine: PetVaccine) => {
    setSelectedVaccin(petvaccine);
    setModeVue("delete");
  }

  const updatePetVaccine = async (petvaccine: PetVaccine) => {
    dataService.Pets.saveVaccinePet(petvaccine).then((response) => { loadData(); });
    onCloseDlg();
  }

  const deletePetVaccine = async () => {
    dataService.Pets.deleteVaccinePet(selectedVaccin.Id).then((response) => { loadData(); });
    onCloseDlg();
  }

  return (

    <div className="card b">
      <div className="card-content ">
        <div className="card-header">
          <h3>Vaccins</h3>
        </div>
        <div className="card-body">

          <ul className="list-group">
            {
              listVaccine != null && listVaccine.map((vaccineItem,index) => (
                <PetVaccinItem key={index} vaccine={vaccineItem} typeId={props.petType} onEditVaccine={openDlgEdit} onDeleteVaccine={openDlgDelete} />
              ))
            }
          </ul>

          <hr className="d-print-none" />
          <div className="card-action clearfix">
            <NavLink to={'/pet-vaccin-edit/0/' + props.petId + '/' + props.petType}>
              <div className="btn btn-outline-primary">Ajouter</div>
            </NavLink>

          </div>
        </div>

        {modeVue === "edit" && <DlgPetVaccin onClose={onCloseDlg} petType={props.petType} vaccineInfo={selectedVaccin} onValid={updatePetVaccine} />}
        {modeVue === "delete" && <DlgDelete onClose={onCloseDlg} libelle={selectedVaccin.Libelle} onDelete={deletePetVaccine} />}

      </div >
    </div >
  );
}
