import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DlgPensionEdit from "../../common/dialog/DlgPensionEdit";
import { Pension } from "../../common/models/pension";
import { dataService } from "../../services/Services";
import { getNoLogoImage } from "../../utils/Tools";

export default function ConfigPension(props: any) {
    const [pensionInfo, setPensionInfo] = useState(new Pension());
    const [modeTarification, setModeTarification] = useState("");
    const [mentionslegales, setMentionslegales] = useState<string[]>([]);
    const [mentionslegalesContrat, setMentionslegalesContrat] = useState<string[]>([]);
    const [modeVue, setModeVue] = useState("display");


    useEffect(() => {

        loadData();

    }, []);

    const openDlgEdit = async () => {
        //setModeVue("edit");
        setModeVue("edit");
    }

    const onValid = async (pension: Pension) => {
        dataService.Configuration.savePension(pension).then((response) => { onCloseDlg(); loadData(); });
    }

    const onCloseDlg = async () => {
        setModeVue("display");
    }

    const loadData = () => {

        dataService.Configuration.getInfoPension().then((response) => { formatMentionsLegales(response.data) });
    }
    const formatMentionsLegales = (pensionInfo: Pension) => {
        if(pensionInfo.Logo==="" || pensionInfo.Logo ===null)
        pensionInfo.Logo = getNoLogoImage();
        setPensionInfo(pensionInfo);
        if (pensionInfo.MentionsLegales != null)
            setMentionslegales(pensionInfo.MentionsLegales.split("\n"));
        if (pensionInfo.MentionsLegalesContrat != null)
            setMentionslegalesContrat(pensionInfo.MentionsLegalesContrat.split("\n"))
        switch (pensionInfo.ModeTarification) {
            case 1:
                setModeTarification("Tarification à la nuit");
                break;
            case 2:
                setModeTarification("Tarification à la demi journée et journée");
                break;
            case 3:
                setModeTarification("Tarification à la journée");
                break;
        }
    }

    return (
        <div className="card b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Informations pension</h3>
                </div>
                <div className="card-body">
                    <form className="form-horizontal">
                        <div className="form-group row">
                            <div className="col-md-9">
                                <div className="form-group row">
                                    <div className="col-md-2">Nom</div>
                                    <div className="col-md-10">
                                        <strong>{pensionInfo.Nom}</strong>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-2">Numéro Siret</div>
                                    <div className="col-md-10">
                                        <strong>{pensionInfo.Siret}</strong>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-2">Représenté par</div>
                                    <div className="col-md-10">
                                        <strong>{pensionInfo.RepresentePar}</strong>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-2">Téléphone</div>
                                    <div className="col-md-10">
                                        <strong>{pensionInfo.NumTel}</strong>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-2">Portable</div>
                                    <div className="col-md-10">
                                        <strong>{pensionInfo.NumPortable}</strong>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-2">Email</div>
                                    <div className="col-md-10">
                                        <strong>{pensionInfo.Email}</strong>
                                    </div>
                                </div>
                          

                            {/* <div className="form-group row">
                            <div className="col-md-2">Nom</div>
                            <div className="col-md-4">
                                <strong>{pensionInfo.Nom}</strong>
                            </div>
                            <div className="col-md-2">Numéro Siret</div>
                            <div className="col-md-4">
                                <strong>{pensionInfo.Siret}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-2">Téléphone</div>
                            <div className="col-md-4">
                                <strong>{pensionInfo.NumTel}</strong>
                            </div>
                            <div className="col-md-2">Portable</div>
                            <div className="col-md-4">
                                <strong>{pensionInfo.NumPortable}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-2">Email</div>
                            <div className="col-md-4">
                                <strong>{pensionInfo.Email}</strong>
                            </div>
                            <div className="col-md-2">Représenté par</div>
                            <div className="col-md-4">
                                <strong>{pensionInfo.RepresentePar}</strong>
                            </div>
                        </div> */}
                            <div className="form-group row">
                                <div className="col-md-2">Adresse</div>
                                <div className="col-md-10">
                                    <div className="d-flex">
                                        <div className="margright"><strong >{pensionInfo.Adresse}</strong></div>
                                        <div className="margright"> <strong>{pensionInfo.CodePostal}</strong></div>
                                        <div className="margright"><strong>{pensionInfo.Ville}</strong></div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2">Mentions légales facture</div>
                                <div className="col-md-10">
                                    {mentionslegales && mentionslegales.map((item, index) =>
                                        <div key={index} ><strong  >{item}</strong></div>)}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2">Mentions légales contrat</div>
                                <div className="col-md-10">
                                    {mentionslegalesContrat && mentionslegalesContrat.map((item, index) =>
                                        <div key={index} ><strong  >{item}</strong></div>)}
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2">Alerte vaccin </div>
                                <div className="col-md-10">
                                    <strong>prévenir {pensionInfo.VaccinAlert} jours avant</strong>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2">Poucentage acompte</div>
                                <div className="col-md-10">
                                    <strong>{pensionInfo.PercentAcompte} %</strong>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-2">Mode de tarification</div>
                                <div className="col-md-4">
                                    <strong>{modeTarification}</strong>
                                </div>
                                {pensionInfo.ModeTarification === 2 && <div className="col-md-2">Heure fin matinée</div>}
                                {pensionInfo.ModeTarification === 2 && <div className="col-md-4">
                                    <strong>{pensionInfo.HeureFinMatin}H</strong>
                                </div>}
                            </div >
                            </div>
                            <div className="col-md-3">
                                <img src={pensionInfo.Logo} style={{maxWidth:'300px', maxHeight:'200px'}} alt="" />
                            </div>
                        </div>                        

                    </form >

                    <hr className="d-print-none" />
                    <div className="card-action clearfix">
                        <NavLink to={'/config-pension-edit'}>
                            <div className="btn btn-outline-primary">Modifier</div>
                        </NavLink>

                    </div >
                </div >
            </div >

            {modeVue === "edit" && <DlgPensionEdit onClose={onCloseDlg} pension={pensionInfo} onValid={onValid} />}
        </div >);

};


