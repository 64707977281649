import React, {  useState } from 'react';
import { MinMaxDate } from '../../common/models/min-max-date';
import { getDateStrFromTimeStamp } from '../../utils/Tools';
import { Registre } from '../../common/models/registre';
import { dataService } from '../../services/Services';

export default function Record(props: any) {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [listYear, setListYear] = useState<number[]>([]);
    const [listRegistre, setListRegistre] = useState<Registre[]>([]);


    React.useEffect(() => {
        dataService.Configuration.getMinMaxDateExploitation().then((response) => { setdate(response.data) });

    }, [props]);


    const setdate = (infoMinMaxDate: MinMaxDate) => {
        var yearMin = new Date(infoMinMaxDate.MinDate * 1000).getFullYear();
        var yearMax = new Date(infoMinMaxDate.MaxDate * 1000).getFullYear();
        let listYear = new Array<number>();
        for (var i = yearMin; i <= (yearMax); i++)
            listYear.push(i);

        setListYear(listYear);

        var selectYear = 0;
        var today = new Date();
        if (yearMax >= today.getFullYear())
            selectYear = today.getFullYear();
        else
            selectYear = yearMax;

        loadData(selectYear);
    }

    const loadData = async (year: number) => {
        var restComparatif = await  dataService.Periodes.getRegistre(year);
         setListRegistre(restComparatif.data) ;
        
        setSelectedYear(year);
    }

    const generatePdf = () => {
        dataService.Periodes.exportPdfL(selectedYear).then(response => openFile(response.data));
    }
    const onChangeYear = (event: any) => {
        setSelectedYear(parseInt(event.target.value));
        loadData(parseInt(event.target.value))
    }

    const openFile = (nom : string) => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', dataService.Url.doc +nom);
        link.setAttribute('download', nom);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }


    return (
        <div className="container-fluid margtop registre">
            <div className="row">
                <div className="col">
                    <div className="d-flex justify-content-between">
                        <h3>Registre</h3>

                        <div>
                           
                            <select className="form-control" onChange={(event) => onChangeYear(event)}  value={selectedYear} >
                                {listYear && listYear.map((year, index) => <option key={index} value={year}>{year}</option>)}
                            </select>
                        </div>
                        <div >

                            <div className='pointer' onClick={generatePdf} ><img src="img/planimalin_export-pdf.png" alt="PDF" /></div>
                        </div>
                    </div >
                </div >
            </div >

            <div className="row">
                <div className="col">
                    <table>
                        <thead>
                            <tr className='entete_titre'>
                                <td colSpan={3}>ENTREE</td>
                                <td colSpan={6}>ANIMAL</td>
                                <td colSpan={4}>SORTIE</td>
                            </tr>
                            <tr className='entete_soustitre'>
                                <td className='td-compteur'></td>
                                <td className='td-date-entree'>Date d'entrée</td>
                                <td className='td-provenance'>Provenance (nom, qualité et adresse du fournisseurde l'animal)</td>
                                <td className='td-espece'>Espèce</td>
                                <td className='td-sexe'>Sexe</td>
                                <td className='td-identification'>Numéro d'identification (Nom)</td>
                                <td className='td-desciption'>Description Race, robe, poils, taille signes particuliers</td>
                                <td className='td-date-naissance'>Date de naissance ou âge</td>
                                <td className='td-info-parent'>Pour chaque naissance dans l'établissement n° d'identification de la mère ou signalement (race, date de naissance ou âge...)</td>
                                <td className='td-date-sortie'>Date de sortie ou mort</td>
                                <td className='td-destination'>Destination (nom, qualité, adresse du destinateure de l'animal)</td>
                                <td className='td-mort'>Cause de la mort de l'animal (maladie, accident,euthanasie, mort naturelle)</td>
                                <td className='td-compteur'></td>
                            </tr>
                        </thead>
                        <tbody>
                            {listRegistre && listRegistre.map((registreItem, index) =>
                                <tr key={index} className='line-content'>
                                    {/* <!-- //entree --> */}
                                    <td className='td-compteur'> {index + 1}  </td>
                                    <td className='td-date-entree'>{getDateStrFromTimeStamp(registreItem.DateDeb)}</td>
                                    <td className='td-provenance'>{registreItem.NomProprietaire}<br />{registreItem.AdresseProprietaire}</td>
                                    {/* <!-- //animal --> */}
                                    <td className='td-espece'>{registreItem.CategorieAnimal}</td>
                                    <td className='td-sexe'>{registreItem.SexeAnimal === 0 ? "Mâle" : "Femelle"}</td>
                                    <td className='td-identification'>{registreItem.IdentificationAnimal}<br /> {registreItem.Nom}</td>
                                    <td className='td-desciption'>{registreItem.RaceAnimal}</td>
                                    <td className='td-date-naissance'>{getDateStrFromTimeStamp(registreItem.DateNaissanceAnimal)}</td>
                                    <td className='td-info-parent'></td>

                                    {/* <!-- //sortie --> */}
                                    <td className='td-date-sortie'>{getDateStrFromTimeStamp(registreItem.DateFin)}</td>
                                    <td className='td-destination'>{registreItem.NomProprietaire}<br />{registreItem.AdresseProprietaire}</td>
                                    <td className='td-mort'></td>
                                    <td className='td-compteur'> {index + 1}</td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>
            </div >
        </div >
    );

}


