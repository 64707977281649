import React, { useState } from 'react';
import AlertVaccin from './AlertVaccin';
import Birthday from './Birthday';
import HomeHeader from './HomeHeader';
import Movement from './Movement';
import Events from './Events';

export default function Home(props:any)  {
    const [selectedDate, setSelectedDate] = useState(new Date().getTime());
    
    const changeDate = (newDate:Date) => {
        setSelectedDate(newDate.getTime());
    }
return (
    <div className='home'>
    <HomeHeader onChangeDate={changeDate}></HomeHeader>
    <div className="container-fluid">
    <div className="row">  
        <div className="col-xl-6">        
            <Movement selectedDate={selectedDate}></Movement>
            <Birthday selectedDate={selectedDate}></Birthday>
        </div>
        <div className="col-xl-6">
            <Events selectedDate={selectedDate}></Events>
           <AlertVaccin selectedDate={selectedDate}></AlertVaccin>
        </div>
    </div>
    <div className="row">  
        <div className="col-xl-6">
        </div>   
    </div>
    </div>
    </div>);
}
