import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Proprio } from "../../common/models/proprio";
import { dataService } from "../../services/Services";


export default function ProprioEdit(props: any) {

    let history = useNavigate();
    const params = useParams()
    const [proprioInfo, setProprioInfo] = useState<Proprio>(new Proprio());
    const { reset,register, handleSubmit } = useForm();
    const [checked, setChecked] = useState(false);


    useEffect(() => {

        loadData();

    }, [params]);

    useEffect(() => {

        reset(proprioInfo);
    
      }, [proprioInfo]);

    const loadData = async () => {
        if (params.id !== undefined) {
            if (parseInt(params.id) > 0) {
                dataService.Proprios.getProprio(parseInt(params.id)).then((response) => { computeProprio(response.data) });
            }
            else {
                newProprio();
            }
        }
    }

    const computeProprio = async (proprio: Proprio) => {
        setChecked(proprio.IsDCD)
        setProprioInfo(proprio);
    }

    const newProprio = async () => {
        let proprioTmp = new Proprio();
        proprioTmp.Id = -1;
        proprioTmp.Adresse = "";
        proprioTmp.IsDCD = false;
        proprioTmp.NumPortable = "";
        proprioTmp.NumPortable2 = "";
        proprioTmp.NumTel = "";
        proprioTmp.AutreContactName = "";
        proprioTmp.AutreContactTel = "";
        proprioTmp.Email = "";
        proprioTmp.Nom = "";
        proprioTmp.ConnuPar = "";
        computeProprio(proprioTmp);
    }

    const onSubmit = (data: any) => {

        var proprio = data;
        proprio.Id = proprio.Id;
        proprio.IsDCD = checked;

        dataService.Proprios.saveProprio(proprio).then((response) => {  history('/proprio/' +response.data.IdResult) });
    }
  
    const handleChange = () => {

        setChecked(!checked);

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid margtop"  >
                <h4>{proprioInfo.Id>0?"Edition propriétaire":"Création propriétaire"}</h4>
                <div className="">
                    <div className="form-group">
                        <label >Nom</label>
                        <input placeholder="Nom" type="text" className="form-control"   {...register("Nom", { required: false })}  />
                    </div>
                    <div className="form-group ">
                        <label >Adresse</label>
                        <textarea placeholder="Adresse" className="form-control note-editor" rows={4}  {...register("Adresse", { required: false })} ></textarea>
                    </div>
                    <div className="form-group ">
                        <label >Téléphone</label>
                        <input placeholder="Téléphone" type="text" className="form-control"  {...register("NumTel", { required: false })}  />
                    </div>
                    <div className="form-group ">
                        <label >Email</label>
                        <input placeholder="Email" type="text" className="form-control"  {...register("Email", { required: false })} />
                    </div>
                    <div className="form-group ">
                        <label >Portable</label>
                        <input placeholder="Portable" type="text" className="form-control"  {...register("NumPortable", { required: false })}  />
                    </div>
                    <div className="form-group ">
                        <label >Portable 2</label>
                        <input placeholder="Portable 2" type="text" className="form-control"  {...register("NumPortable2", { required: false })}  />
                    </div>
                    <div className="form-group ">
                        <label >Contact nom</label>
                        <input placeholder="Contact nom" type="text" className="form-control"  {...register("AutreContactName", { required: false })} />
                    </div>
                    <div className="form-group ">
                        <label >Contact tél.</label>
                        <input placeholder="Contact téléphone" type="text" className="form-control"  {...register("AutreContactTel", { required: false })} />
                    </div>
                    <div className="form-group ">
                        <label >Nous a connu</label>
                        <input placeholder="Connu par" type="text" className="form-control"  {...register("ConnuPar", { required: false })}  />
                    </div>
                    <div className="form-group ">
                        <div className="col-12">
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" checked={checked} {...register('IsDCD', { required: false })} onChange={handleChange} />
                                <label className="form-check-label">Est décédé</label>
                            </div>
                        </div>
                    </div>
                    <div className="ligne">
                        <div id="editEtatmessageError" className="text-danger"></div>
                    </div>
                </div >
                <div className="d-flex justify-content-end margtop margbottom">
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div className="btn btn-outline-secondary margright" onClick={() => history(-1)} >Annuler</div>
                </div>
            </div>

        </form>

    );
}