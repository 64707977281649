import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Proprio } from "../../common/models/proprio";
import { dataService } from "../../services/Services";


export default function ProprioVetoEdit(props: any) {

    let history = useNavigate();
    const params = useParams()
    const [proprioInfo, setProprioInfo] = useState<Proprio>(new Proprio());
    const { reset, register, handleSubmit } = useForm();
   


    useEffect(() => {

        loadData();

    }, [params]);

    useEffect(() => {

        reset(proprioInfo);

    }, [proprioInfo]);

    const loadData = async () => {
        if (params.id !== undefined) {
            if (parseInt(params.id) > 0) {
                dataService.Proprios.getVeto(parseInt(params.id)).then((response) => { computeProprio(response.data) });
            }
            
        }
    }

    const computeProprio = async (proprio: Proprio) => {      
        setProprioInfo(proprio);
    }

    const onSubmit = (data: any) => {

        var proprio = data;
        proprio.Id = proprio.Id;
        dataService.Proprios.saveVeto(proprio).then((response) => { history(-1); });
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-fluid margtop"  >
                <h4>Edition vétérinaire</h4>
                <div className="">
                    <div className="form-group ">
                        <label >Nom</label>
                        <input placeholder="Nom" type="text" className="form-control"  {...register("VetoName", { required: false })} />
                    </div>
                    <div className="form-group ">
                        <label >Adresse</label>
                        <textarea placeholder="Adresse" className="form-control note-editor" rows={4}  {...register("VetoAdresse", { required: false })} />
                    </div>
                    <div className="form-group">
                        <label >Téléphone</label>
                        <input placeholder="Téléphone" type="text" className="form-control"  {...register("VetoNumTel", { required: false })}  />
                    </div>
                    <div className="form-group">
                        <label >Téléphone 2</label>
                        <input placeholder="Téléphone 2" type="text" className="form-control" {...register("VetoNumTel2", { required: false })}/>
                    </div>
                    <div className="ligne">
                        <div id="editEtatmessageError" className="text-danger"></div>
                    </div>
                </div >
                <div className="d-flex justify-content-end margtop margbottom">
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />

                    <div className="btn btn-outline-secondary margright" onClick={() => history(-1)} >Annuler</div>

                </div>
            </div>

        </form>

    );
}