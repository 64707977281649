import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { dataService } from "../../services/Services";
import DatePicker from "react-datepicker";
import { extractDateFromTimeStamp, getDateFromTimeStamp } from "../../utils/Tools";
import { Pet } from "../../common/models/pet";
import { TypePet } from "../../common/models/type-pet";
import { Navigate, NavLink, useNavigate, useParams } from "react-router-dom";
import { ConfigContextType } from "../../common/models/config";
import { ConfigContext } from "../../context/ConfigContext";
import { Box } from "../../common/models/box";
import { Tarification } from "../../common/models/tarification";
import { Periode, PeriodeStartEnd } from "../../common/models/periode";
import TimePicker from "../../components/TimePicker";
import Select from "react-select";
import DlgDelete from "../../common/dialog/DlgDelete";
import DlgConfirm from "../../common/dialog/DlgConfirm";


export default function PeriodeEditComposant(props: { onClose: any, periode: Periode, onUpdate: any }) {

  let navigate = useNavigate();
  const params = useParams();

  const { config } = useContext(ConfigContext) as ConfigContextType;

  const [modeVue, setModeVue] = useState("display");
  const [periodeInfo, setPeriodeInfo] = useState<Periode>(new Periode());
  const [listPet, setListPet] = useState<Pet[]>([]);
  const [listBox, setListBox] = useState<Box[]>([]);
  const [listTarif, setListTarif] = useState<Tarification[]>([]);
  const { reset, register, setValue, handleSubmit } = useForm();
  const [selectedDateDeb, setSelectedDateDeb] = useState(new Date());
  const [selectedDateFin, setSelectedDateFin] = useState(new Date());
  const [selectedTimeDeb, setSelectedTimeDeb] = useState(0);
  const [selectedTimeFin, setSelectedTimeFin] = useState(0);
  const [selectedOption, setSelectedOption] = useState<Pet>(new Pet());
  const [messageError, setMessageError] = useState<String[]>([]);
  const [listOtherPet, setListOtherPet] = useState<Pet[]>([]);
  const [listSamePeriode, setListSamePeriode] = useState<Periode[]>([]);
  const [refreshId, setRefreshId] = useState(0);
  const [listPeriode, setListPeriode] = useState<Periode[]>([]);


  useEffect(() => {

    computeInfo(props.periode);

  }, [props.periode]);


  useEffect(() => {

    reset(periodeInfo);

  }, [periodeInfo]);


  const computeInfo = async (periode: Periode) => {
    var restbox = await dataService.Boxs.listBox(periode.TypeAnimal);
    var resttarif = await dataService.Tarifications.listTarification(periode.TypeAnimal);

    var restPet = await dataService.Pets.listPet(periode.TypeAnimal);

    if (periode.Id > 0) {
      var restOtherPeriode = await dataService.Periodes.listSameMove(periode.Id);
      setListSamePeriode(restOtherPeriode.data);
    }

    setListBox(restbox.data);
    setListTarif(resttarif.data);
    setListPet(restPet.data);

    setValue("IdBox", periode.IdBox);
    setValue("TarifId", periode.TarifId);
    var dateDebTs = extractDateFromTimeStamp(periode.DateDeb);
    var dateFinTs = extractDateFromTimeStamp(periode.DateFin);
    setSelectedDateDeb(getDateFromTimeStamp(dateDebTs));
    setSelectedDateFin(getDateFromTimeStamp(dateFinTs));
    setSelectedTimeDeb(periode.DateDeb - dateDebTs);
    setSelectedTimeFin(periode.DateFin - dateFinTs);
    if (periode.IdAnimal > 0) {
      var pet = restPet.data.find((x: Pet) => x.Id === periode.IdAnimal);
      if (pet !== undefined) {
        setSelectedOption(pet);
      }
    }
    setPeriodeInfo(periode);

  }

  const setDateDeb = (newDate: any) => {
    if (newDate !== null) {
      setSelectedDateDeb(newDate);
    }
  }

  const setDateFin = (newDate: any) => {
    if (newDate !== null) {
      setSelectedDateFin(newDate);
    }
  }


  const handleChange = async (selectedOption: any) => {
    setSelectedOption(selectedOption);

    
    let list = listPet.filter((x: Pet) => x.IdProprietaire === selectedOption.IdProprietaire && x.Id !== selectedOption.Id);
    list.forEach(function (pet, index) { pet.IsVisible = true; });

    setListOtherPet(list);

  };

  const handleChangeSelectPet = (petId: number) => {

    listOtherPet.forEach(function (pet, index) { pet.IsVisible = (pet.Id === petId ? !pet.IsVisible : pet.IsVisible); });
    setRefreshId(refreshId + 1);

  };

  const handleChangeSelectPeriode = (periodeId: number) => {

    listSamePeriode.forEach(function (periode, index) { periode.IsSelected = (periode.Id === periodeId ? !periode.IsSelected : periode.IsSelected); });
    setRefreshId(refreshId + 1);

  };
  

  const updateTimeDeb = async (newTime: number) => {
    setSelectedTimeDeb(newTime);
  };

  const updateTimeFin = async (newTime: number) => {
    setSelectedTimeFin(newTime);
  };

  const onClose = async () => {
    props.onClose();

  };
  const openDelete = async () => {
    setModeVue("delete");
  };

  const openConfirm=async() =>{
    setModeVue("confirm");
  }

  const closeDlg = async () => {
    setModeVue("display");
  };

  const onDelete = async () => {
    let listPeriodeId = [];
    listPeriodeId.push(periodeInfo.Id);
    
    for (var i = 0; i < listSamePeriode.length; i++) {
      if (listSamePeriode[i].IsSelected) {         
          listPeriodeId.push( listSamePeriode[i].Id);
      }      
    }
    dataService.Periodes.deleteListPeriode(listPeriodeId).then((response) => { props.onUpdate(); });
    // dataService.Periodes.deletePeriode(periodeInfo.Id).then((response) => { props.onUpdate(); });
  };

  const onSubmit = async (data: any) => {
    setMessageError([]);
    
    let listPeriodeTmp = [];
    var periode = periodeInfo;
    periode.IdBox = data.IdBox;
    periode.TarifId = data.TarifId;
    periode.Montant = data.Montant;
    periode.IdAnimal = selectedOption.Id;
    if (periode.Montant === undefined)
      periode.Montant = 0;
    periode.Acompte = data.Acompte;
    if (periode.Acompte === undefined)
      periode.Acompte = 0;
    periode.DateDeb = (selectedDateDeb.getTime()) / 1000 + selectedTimeDeb;
    periode.DateFin = (selectedDateFin.getTime()) / 1000 + selectedTimeFin;

    if (periode.IdAnimal !== undefined && periode.IdAnimal > 0 &&
      !isNaN(periode.DateDeb) && !isNaN(periode.DateFin) &&
      (periode.DateDeb < periode.DateFin)) {
     
        listPeriodeTmp.push(periode);
      if (periode.Id <= 0) {
        for (var i = 0; i < listOtherPet.length; i++) {
          if (listOtherPet[i].IsVisible) {
            var pertmp = { ...periode }
            pertmp.IdAnimal = listOtherPet[i].Id;
            listPeriodeTmp.push(pertmp);
          }
        }
      } else {
        for (var i = 0; i < listSamePeriode.length; i++) {
          if (listSamePeriode[i].IsSelected) {
           
            listSamePeriode[i].IdBox = periode.IdBox;
            listSamePeriode[i].TarifId = periode.TarifId;
            listSamePeriode[i].Montant = periode.Montant;
            listSamePeriode[i].Montant = periode.Montant;
            listSamePeriode[i].Acompte = periode.Acompte;
            listSamePeriode[i].DateDeb = periode.DateDeb;
            listSamePeriode[i].DateFin = periode.DateFin;
            listPeriodeTmp.push( listSamePeriode[i]);
          }
        }
      }
      let periodeVerif = new PeriodeStartEnd();
      periodeVerif.DateDeb = (selectedDateDeb.getTime()) / 1000 + selectedTimeDeb;
      periodeVerif.DateFin = (selectedDateFin.getTime()) / 1000 + selectedTimeFin;
  
      var restexist = await  dataService.Periodes.existEventClose(periodeVerif);
      if(restexist.data.Result===true)
        {
          setListPeriode(listPeriodeTmp);
          openConfirm();
        }
      else
        dataService.Periodes.saveListPeriode(listPeriodeTmp).then((response) => { props.onUpdate(); });
  
    } else {
      let message = new Array<string>();
      message.push("Veuillez remplir correctement tous les champs :");
      if (periode.IdAnimal === undefined || periode.IdAnimal <= 0)
        message.push(" - Animal incorrect");
      if (isNaN(periode.DateDeb) || isNaN(periode.DateFin))
        message.push(" - date incorrect");
      if (periode.DateDeb >= periode.DateFin)
        message.push(" - date de fin doit être supérieure à la date de début");

      setMessageError(message);
    }
  }
  const sendUpdatePeriode = async () => {
    dataService.Periodes.saveListPeriode(listPeriode).then((response) => { props.onUpdate(); });
  }
  

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container-fluid margtop"  >
          <h4>{periodeInfo.Id > 0 ? "Modification période" : "Ajout période"}</h4>
          <div className="">
            <div className="form-group ">
              <label >Box</label>
              <select className="form-control" {...register("IdBox", { required: false })} >
                {listBox && listBox.map((b) => <option key={b.Id} value={b.Id}>{b.Nom}</option>)}
              </select>
            </div>

            <div className="form-group ">
              <div className="d-flex"><label className="margright" >Animal</label>
              {selectedOption && selectedOption.Id > 0 && <div className="d-flex margleft">
                    <NavLink to={'/pet/' + selectedOption.Type + "/" + selectedOption.Id} title="Aller sur la fiche de l'animal">
                      <i className="fa-solid fa-paw margright" />
                    </NavLink>
                    <NavLink to={'/proprio/' + selectedOption.IdProprietaire} title="Aller sur la fiche du propriétaire">
                      <i className="fa-solid fa-user" />
                    </NavLink>
                  </div >}
              </div>
              <div className="row">
                <div className="col-12">
                  {periodeInfo.Id <= 0 && <Select
                    classNamePrefix="react-select-header"
                    getOptionLabel={option => `${option.Nom} (${option.NomProprietaire})`}
                    value={selectedOption}
                    onChange={handleChange}
                    options={listPet}
                  />}
                  {periodeInfo.Id > 0 &&
                    <div className="margtop"><span className="text-bold">{periodeInfo.Nom} </span>({periodeInfo.NomProprietaire})</div>
                  }
                </div>
              
              </div>
              {listOtherPet && listOtherPet.length > 0 &&
                <div className="text-danger">
                  <div>Voulez vous ajouter les animaux suivant dans le même box?</div>
                  {listOtherPet.map((item, key) =>
                    <div key={key} className="row margleft">
                      <div className="col-12">
                        <div className="form-group form-check">
                          <input type="checkbox" className="form-check-input" checked={item.IsVisible} onChange={event => handleChangeSelectPet(item.Id)} />
                          <label className="form-check-label" >{item.Nom}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>}
              {listSamePeriode && listSamePeriode.length > 0 &&
                <div className="text-danger">
                  <div >Voulez vous aussi appliquer les modification sur les périodes des animaux suivant ?</div>
                  {listSamePeriode.map((item, key) =>
                    <div key={key} className="row margleft">
                      <div className="col-12">
                        <div className="form-group form-check">
                          <input type="checkbox" className="form-check-input" checked={item.IsSelected} onChange={event => handleChangeSelectPeriode(item.Id)} />
                          <label className="form-check-label" >{item.Nom}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>}
            </div >

            <div className="form-group ">
              <div className="row">
                <div className="col-12">
                  <div className='line-datetime-picker' >
                    <div className="div-datetime">
                    <div className="libelle">Date d'arrivée</div>
                    <DatePicker selected={selectedDateDeb} className="form-control" onChange={(date) => setDateDeb(date)} dateFormat="dd/MM/yyyy" />
                </div>
                    <TimePicker date={selectedTimeDeb} onTimeChange={updateTimeDeb} />
                  </div>
                </div>
              </div>
            </div >
            <div className="form-group ">
              <div className="row">
              <div className="col-12">
                  <div className='line-datetime-picker' >
                    <div className="div-datetime">
                    <div className="libelle">Date de départ</div>
                  <DatePicker selected={selectedDateFin} className="form-control" onChange={(date) => setDateFin(date)} dateFormat="dd/MM/yyyy" />
                  </div>
                  <TimePicker date={selectedTimeFin} onTimeChange={updateTimeFin} />
                </div>
                </div>
              </div>
            </div >
            {config.ExistFacture === true && <div className="form-group " >
              <label >Tarif</label>
              <select className="form-control"  {...register("TarifId", { required: false })}  >
                {listTarif && listTarif.map((tarif) => <option key={tarif.Id} value={tarif.Id}> {tarif.Libelle} ({tarif.Montant} €)</option>)}
              </select >
            </div >
            }
            {config.ExistFacture === false && <div className="form-group "  >
              <label >Montant</label>
              <div>
                <input placeholder="Montant" type="number" step="0.01" className="form-control" {...register("Montant", { required: false })} />
              </div>
              <label >Acompte</label>
              <div >
                <input placeholder="Acompte" type="number" step="0.01" className="form-control"  {...register("Acompte", { required: false })} />
              </div>
            </div >}
            <div className="ligne">
              <div className="text-danger">
                {messageError && messageError.map((message, index) => <div key={index} > {message} </div>)}
              </div>
            </div>
          </div >
          <div className="d-flex justify-content-end margtop margbottom">
            {periodeInfo.Id>0&& <div className="btn btn-outline-danger margright" onClick={() => openDelete()}>Supprimer</div>}
            <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
            <div className="btn btn-outline-secondary margright" onClick={() => onClose()} >Annuler</div>
          </div>
        </div>

      </form>
      {modeVue === "delete" && <DlgDelete onClose={closeDlg} libelle={'la période pour ' + periodeInfo.Nom + (listOtherPet.length>0?' et ceux sélectionnés':"")} onDelete={onDelete} />}
      {modeVue === "confirm" && <DlgConfirm onClose={closeDlg} libelle={'La pension est fermée pour cette période, voulez vous quand même la créer ?'} onConfirm={sendUpdatePeriode} />}
    </div>
  );
}