import { useState, useEffect } from "react";
import { NavLink,   } from 'react-router-dom'
import { Pet } from "../../common/models/pet";
import { dataService } from "../../services/Services";
import { renderImgAnimal } from "../../utils/Tools";

function  Birthday( props:any ) {
    const [anniversaires, setAnniversaires] = useState<Pet[]>([]);
    useEffect(() => {
     
        dataService.Pets.listBirthday(props.selectedDate).then((response) => { setAnniversaires(response.data) });

    }, [props.selectedDate]);

    return (
        <div className="card b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Anniversaires</h3>
                </div>
                <div className="card-body limit500">

                    {anniversaires !== undefined && anniversaires.map((anniversaire) => (
                        <div key={anniversaire.Id}>
                            <img src={renderImgAnimal(anniversaire.Type)} alt="cat" />
                            <NavLink to={'/pet/' + anniversaire.Type + '/' + anniversaire.Id}>
                                <span className='pointerBold'   >
                                    {anniversaire.Nom}
                                </span>
                            </NavLink>
                            <NavLink to={"/proprio/" + anniversaire.IdProprietaire}>
                                <span className='pointerBold'  >
                                    &nbsp;({anniversaire.NomProprietaire})
                                </span>
                            </NavLink>
                            <span>&nbsp;fête ses &nbsp;</span>
                            <span>{anniversaire.Age}</span>
                            <span>&nbsp;ans&nbsp;</span>

                        </div>
                    ))}
                    {(anniversaires === undefined || anniversaires.length === 0) && <div >Aucune anniversaire</div>
                    }
                </div>
            </div>
        </div>);

};
export default Birthday;

