import axios from "axios";
import md5 from "react-native-md5"
import { Periode, PeriodeStartEnd } from "../common/models/periode";
import { Pet } from "../common/models/pet";
import { Proprio } from "../common/models/proprio";
import qs from 'qs';
import { format } from 'date-fns'
import { Box } from "../common/models/box";
import { Pension } from "../common/models/pension";
import { PetVaccine } from "../common/models/pet-vaccine";
import { Vaccine } from "../common/models/vaccine";
import { Invoice } from "../common/models/invoice";
import { Tarification } from "../common/models/tarification";
import { PlanningEvent } from "../common/models/planning-event";

const Url = {
    ws: process.env.NODE_ENV === 'production' ? 'https://gestion.planimalin.fr/Services/webservice.php' : 'http://localhost/Services/webservice.php',
    doc: process.env.NODE_ENV === 'production' ? 'https://gestion.planimalin.fr/export/' : "http://localhost/export/"
}

const client = axios.create({   
    
    headers: {
        'trust': md5.hex_md5("Planimalin4t#l%a&s$2" + format(new Date().getTime(), 'MMddyyyy')),//+ Tools.convertDate(new Date().getTime())),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
  });

  const clientFile = axios.create({   
    
    headers: {
        'trust': md5.hex_md5("Planimalin4t#l%a&s$2" + format(new Date().getTime(), 'MMddyyyy')),//+ Tools.convertDate(new Date().getTime())),
        'Content-Type': 'multipart/form-data'
      }
  });
  
  //- or after instance has been created
  //client.defaults.headers.post['header1'] = 'value'
  
  //- or before a request is made
  // using Interceptors
   client.interceptors.request.use((req) => {    
     var user = JSON.parse(localStorage.getItem('userpln') || '{}');
     if(req?.headers!== undefined && user !== undefined && user !== null){
         req.headers['puid'] = `${user.PensionId}`;
         req.headers['uuid'] = `${user.UserId}`;
     }
     return req;
   });

   clientFile.interceptors.request.use((req) => {    
    var user = JSON.parse(localStorage.getItem('userpln') || '{}');
    if(req?.headers!== undefined && user !== undefined && user !== null){
        req.headers['puid'] = `${user.PensionId}`;
        req.headers['uuid'] = `${user.UserId}`;
    }
    return req;
  });

const requests = {
    post: (body:any) => client.post(Url.ws,qs.stringify(body))    
};


const requestsFile = {
    post: (body:any) => clientFile.post(Url.ws,body)    
};


const Boxs = {
    listAllBox: () => requests.post({ getListBox: -1 }),
    listBox: (typeAnimal:number) => requests.post({ getListBox: typeAnimal }),
//    saveBox: (infoBox:Box) => requests.post({ insertUpdateBox: JSON.stringify(infoBox) }),
    saveBox: (infoBox:Box) => requests.post({ insertUpdateBox: infoBox.Id,nombox:infoBox.Nom,typeanimal:infoBox.CategorieAnimal }),
    deleteBox: (idBox:number) => requests.post({ deleteBox: idBox })
};
const Periodes = {
    listMoveDay: (date:number) => requests.post({ getListMouvement: date/1000 }),
    listEvent: (datedeb:number,datefin:number) => requests.post({ getListEvent: 0,dateDeb:datedeb,dateFin:datefin}),
    listMoveAnimal: (idanimal:number) => requests.post({ getListPeriodeAnimal: idanimal }),
    listMoveProprio: (idproprio:number) => requests.post({ getListPeriodeProprietaire: idproprio }),
    getPeriode: (idperiode:number) => requests.post({ getInfoPeriode: idperiode }),
    //listPeriodeDate: (type:number, datedeb:number, datefin:number) => requests.post({ getListPeriodeDate: type, dateDeb: datedeb/1000, dateFin: datefin/1000 }),
    savePeriode: (infoPeriode:Periode) => requests.post({ insertUpdatePeriode: JSON.stringify(infoPeriode) }),
    saveListPeriode: (infoPeriode:Periode[]) => requests.post({ insertListPeriodes: JSON.stringify(infoPeriode) }),
    deletePeriode: (id:number) => requests.post({ deletePeriode: id }),
    deleteListPeriode: (infoPeriode:number[]) => requests.post({ deleteListPeriodes: JSON.stringify(infoPeriode) }),
    listPeriodeInvoice: (idproprio:number) => requests.post({ getListPeriodeWithoutInvoice: idproprio }),
    listMovePet: (idproprio:number) => requests.post({ getListPeriodeAnimal: idproprio }),
    listMoveDate: (type: number,datedeb:number,datefin:number) => requests.post({ getListPeriodeDate: type,dateDeb:datedeb,dateFin:datefin}),
    updatePeriodeState: (idperiode:number,state:number) => requests.post({ updatePeriodeState: idperiode,etat:state }),
    getRegistre: (year:number) => requests.post({ getRegistre: year }),
    exportPdf: (year:number) => requests.post({ getPDFRegistre: year }),
    exportPdfL: (year:number) => requests.post({ getPDFRegistreL: year }),
    listSameMove: (id:number) => requests.post({ listSameMove: id }),
    listEventDay:(date:number)  => requests.post({ getListEventDay: date/1000 }),
    saveEvent: (infoEvent:PlanningEvent) => requests.post({ insertUpdateEvent: JSON.stringify(infoEvent) }),
    deleteEvent: (id:number) => requests.post({ deleteEvent: id }),
    existEventClose: (periodeInfo:PeriodeStartEnd) => requests.post({ existEventClose: JSON.stringify(periodeInfo) }),
};

const Pets = {
    listPetFull: (typeAnimal:number) => requests.post({ getListAnimauxFull: typeAnimal }),
    listPet: (typeAnimal:number) => requests.post({ getListAnimaux: typeAnimal }),
    getPet: (id:number) => requests.post({ getInfoAnimal: id }),
    savePet: (infoAnimal:Pet) => requests.post({ insertUpdateAnimal: JSON.stringify(infoAnimal) }),
    listBirthday: (date:number) => requests.post({ getAnniversaire: date/1000 }),
    listRelation: (id:number,typeRelation:number) => requests.post({ getRelationAnimaux: id,typeRelation:typeRelation }),
    deleteRelation: (typeRel: number,idAnimal1: number,idAnimal2: number) => requests.post({ deleteRelation: typeRel,idAnimal1:idAnimal1,idAnimal2:idAnimal2 }),
    insertRelation: (typeRel: number,idAnimal1: number,idAnimal2: number) => requests.post({ insertRelation: typeRel,idAnimal1:idAnimal1,idAnimal2:idAnimal2 }),
    listPetForProprio: (idProprio:number) => requests.post({ getListProprietaireAnimaux: idProprio }),
    deletePet: (id:number) => requests.post({ deleteAnimal: id }),
    saveInfoCompl: (infoAnimal:Pet) => requests.post({ updateAnimalInfoCompl: JSON.stringify(infoAnimal) }),
    getListVaccine: (typeAnimal: number) => requests.post({ getListVaccine: typeAnimal }),
    getListVaccineForAnimal: (idPet: number) => requests.post({ getListVaccineForAnimal: idPet }),
    getInfoVaccinePet: (idVaccinPet: number) => requests.post({ getInfoVaccinePet: idVaccinPet }),
    saveVaccinePet: (infoVaccine: PetVaccine) => requests.post({ insertUpdateVaccinePet: JSON.stringify(infoVaccine) }),
    saveVaccine: (infoVaccine: Vaccine) => requests.post({ insertUpdateVaccine: JSON.stringify(infoVaccine) }),
    deleteVaccine: (id: number) => requests.post({ deleteVaccine: id}),
    deleteVaccinePet: (id: number) => requests.post({ deleteVaccinePet: id }),
    listAlertVaccin: (date:number) => requests.post({ getAlertVaccin: date/1000 }),
    updateAlertVaccinView: (id: number) => requests.post({ updateAlertVaccinView: id }),
    listRace: (type: number) => requests.post({ getListRace: type }),
    
};
 
const Proprios = {
    listProprioEntete: () => requests.post({ getListProprietaire: 1 }), 
    listProprioTel: () => requests.post({ getListProprietaire: 2 }), 
    listAll: () => requests.post({ getListProprietaire: -1 }), 
    getProprio: (id:number) => requests.post({ getInfoProprietaire:  id  }),
    getVeto: (id:number) => requests.post({ getInfoProprietaire:  id  }),    
    saveProprio: (infoProprio:Proprio) => requests.post({ insertUpdateProprietaire: JSON.stringify(infoProprio) }),
    saveVeto: (infoProprio:Proprio) => requests.post({ editVeterinaire: JSON.stringify(infoProprio) }),
    deleteProprio: (id:number) => requests.post({ deleteProprietaire: id }),
};

const Configuration = {
    getPages: () => requests.post({ getListPage: 0 }),
    getListCategorie: () => requests.post({ getListCategorie: -1 }),
    getMinMaxDateExploitation: () => requests.post({ getMinMaxDateExploitation: 0 }),
    getInfoPension: () => requests.post({ getInfoPension: 1 }),
    savePension: (infoPension: Pension) => requests.post({ updateInfoPension:JSON.stringify(infoPension) }),
    getConfigPension: () => requests.post({ getConfigPension: 1 }),
    updateLogoPension: (logo:string ) => requests.post({updateLogoPension: logo} ),
    getDocuments: (typeDocument:number ) => requests.post({getDocuments: typeDocument} ),
};
      

const Users = {
    getUserInfo: (login:string, mdp:string) => requests.post({ getUserInfo: login, password: md5.hex_md5(mdp) }),
    getUserInfoWithId: (idUser:number) => requests.post({ getUserInfoWithId: idUser })
};


// getUserInfo(username: string, password: string): Observable<User> {
//     this.md5Int= new Md5();
//     var pass = (String)(this.md5Int.appendStr(password).end());
//     return this.http.post<User>(environment.apiURL, new HttpParams().set('getUserInfo', username).set('password', pass), this.getHhttpOPtions())
//       .pipe(
//         map(user => {
//               if (user && user.UserId>0) {
//                 // store user details and jwt token in local storage to keep user logged in between page refreshes
//                 localStorage.setItem('currentUser', JSON.stringify(user));
//                // this.currentUserSubject.next(user);
//               }
//               return user;
//              } ),
//              retry(1),
//              catchError(this.handleError)
//       );
  
  
//   }
  


const Statistique = {
    getComparatifPeriode: (typeAnimal:number) => requests.post({ getComparatifPeriode: typeAnimal }),
    getStatYearByCategorie: ( debut:number, fin:number) => requests.post({ getStatYearByCategorie: 0,debut:debut/1000,fin:fin/1000 }),
    getInOutForYear: ( debut:number, fin:number) => requests.post({ getInOutForYear: 0, debut: debut/1000, fin: fin/1000 })

};

const Invoices={
    listInvoice : (proprioId: number,start: number,end: number) => requests.post({ getListInvoice: proprioId,start:start,end:end }),  
  getInvoice:(invoiceId: number)=> requests.post({ getInfoInvoice: invoiceId }),   
  saveInvoice:(infoInvoice: Invoice)=> requests.post({ insertUpdateInvoice: JSON.stringify(infoInvoice) }), 
  getNumInvoice:()=> requests.post({ getNumInvoice: 0 }),  
  deleteInvoice:(invoiceId: number)=> requests.post({ deleteInvoice: invoiceId }),   
  saveReglement:(invoiceId: number,dateReglement: number,typeReglement:number,paiementMail:number)=> requests.post({ saveReglement: invoiceId,date:dateReglement,type:typeReglement,sendRecap:paiementMail }),
}

const Tarifications ={
    listTarification:(categorieAnimal: number)=> requests.post({ getListTarification: categorieAnimal }),
      saveTarification:(infoTarif: Tarification)=> requests.post({ insertUpdateTarification: JSON.stringify(infoTarif) }),   
      deleteTarification:(id: number)=> requests.post({ deleteTarification: id }),   

}

  

export const dataService = {
    Periodes,
    Pets,
    Proprios,
    Boxs,
    Configuration,
    Users,
    Statistique,
    Invoices,
    Tarifications,
    Url
};
