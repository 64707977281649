import './App.css';
import MainRoutes from './components/MainRoutes';
import ConfigProvider from './context/ConfigContext';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale("fr",fr)
setDefaultLocale('fr')

function App() {
 
  

  return (
    <ConfigProvider>
      <div className="app">
        <MainRoutes />
      </div>
    </ConfigProvider>
  );
}

export default App;
