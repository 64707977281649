import {  useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Proprio } from "../models/proprio";


function DlgProprioEdit(props: { proprio: Proprio, onClose: any, onValid: any }) {


    const { register,  handleSubmit } = useForm();
    const [checked, setChecked] = useState(false); 
  
    useEffect(() => {
        if (props.proprio !== undefined) {

            setChecked(props.proprio.IsDCD)
        }
    }, [props.proprio]);



    const onSubmit = (data: any) => {
        
        var proprio = data;
        proprio.Id = props.proprio.Id;
        proprio.IsDCD=checked;
        props.onValid(proprio);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    const handleChange = () => { 
    
        setChecked(!checked); 
        
      };

    return (
        <Dialog
            title="Information propriétaire"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>

            <div className="dlg_proprio_edit">
                <div className="form-group row">
                    <label className="col-3 col-form-label">Nom</label>
                    <div className="col-9">
                        <input placeholder="Nom" type="text" className="form-control"   {...register("Nom", { required: false })} defaultValue={props.proprio.Nom} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Adresse</label>
                    <div className="col-9">
                        <textarea placeholder="Adresse" className="form-control note-editor" rows={4}  {...register("Adresse", { required: false })} defaultValue={props.proprio.Adresse}></textarea>
                </div>
            </div>
            <div className="form-group row">
                <label className="col-3 col-form-label">Téléphone</label>
                <div className="col-9">
                    <input placeholder="Téléphone" type="text" className="form-control"  {...register("NumTel", { required: false })} defaultValue={props.proprio.NumTel} />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-3 col-form-label">Email</label>
                <div className="col-9">
                    <input placeholder="Email" type="text" className="form-control"  {...register("Email", { required: false })} defaultValue={props.proprio.Email} />

                </div>
            </div>
            <div className="form-group row">
                <label className="col-3 col-form-label">Portable</label>
                <div className="col-9">
                    <input placeholder="Portable" type="text" className="form-control"  {...register("NumPortable", { required: false })} defaultValue={props.proprio.NumPortable} />

                </div>
            </div>
            <div className="form-group row">
                <label className="col-3 col-form-label">Portable 2</label>
                <div className="col-9">
                    <input placeholder="Portable 2" type="text" className="form-control"  {...register("NumPortable2", { required: false })} defaultValue={props.proprio.NumPortable2} />

                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 col-form-label">Contact nom</label>
                <div className="col-9">
                    <input placeholder="Contact nom" type="text" className="form-control"  {...register("AutreContactName", { required: false })} defaultValue={props.proprio.AutreContactName} />

                </div>
            </div>
            <div className="form-group row">
                <label className="col-3 col-form-label">Contact tél.</label>
                <div className="col-9">
                    <input placeholder="Contact téléphone" type="text" className="form-control"  {...register("AutreContactTel", { required: false })} defaultValue={props.proprio.AutreContactTel} />

                </div>
            </div>
            <div className="form-group row">
                <label className="col-3 col-form-label">Nous a connu</label>
                <div className="col-9">
                    <input placeholder="Connu par" type="text" className="form-control"  {...register("ConnuPar", { required: false })} defaultValue={props.proprio.ConnuPar} />

                </div>
            </div>

            <div className="form-group row">
                <div className="col-12">
                    <div className="form-group form-check">
                        <input  type="checkbox"  className="form-check-input"  checked={checked} {...register('IsDCD', { required: false })} onChange={handleChange}   />
                        <label className="form-check-label">Est décédé</label>
                    </div>
                </div>
            </div>
            <div className="ligne">
                <div id="editEtatmessageError" className="text-danger"></div>
            </div>
        </div >

        </Dialog >
    );
}
export default DlgProprioEdit;