import React, { useState } from 'react';
import { MinMaxDate } from '../../common/models/min-max-date';
import { dataService } from '../../services/Services';
import { NavLink } from "react-router-dom";
import { Invoice } from '../../common/models/invoice';
import { getDateStr2FromTimeStamp } from '../../utils/Tools';

import { CreateListInvoice } from '../../utils/GenFacturePdf';
import { Pension } from '../../common/models/pension';
export default function InvoiceList(props: any) {
    const [infoPension, setInfoPension] = useState(new Pension());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [listYear, setListYear] = useState<number[]>([]);
    const [filterText, setFilterText] = useState("");
    const [listInvoice, setListInvoice] = useState<Invoice[]>([]);
    const [resteInvoice, setRestInvoice] = useState(0);
    const [totalInvoice, setTotalInvoice] = useState(0);


    React.useEffect(() => {
        dataService.Configuration.getInfoPension().then((response) => { setInfoPension(response.data) });
        dataService.Configuration.getMinMaxDateExploitation().then((response) => { setdate(response.data) });

    }, [props]);


    const setdate = (infoMinMaxDate: MinMaxDate) => {
        var yearMin = new Date(infoMinMaxDate.MinDate * 1000).getFullYear();
        var yearMax = new Date(infoMinMaxDate.MaxDate * 1000).getFullYear();
        let listYear = new Array<number>();
        for (var i = yearMin; i <= (yearMax); i++)
            listYear.push(i);

        setListYear(listYear);

        var selectYear = 0;
        var today = new Date();
        if (yearMax >= today.getFullYear())
            selectYear = today.getFullYear();
        else
            selectYear = yearMax;

        loadData(selectYear, -1);
    }

    const loadData = async (year: number, month: number) => {

        var mindate = -1;
        var maxdate = -1;
        if (year > 0) {
            mindate = Date.UTC(year, month > 0 ? month - 1 : 0, 1) / 1000;//.getTime();
            maxdate = Date.UTC(year, month > 0 ? month : 12, 0, 23, 59, 59) / 1000;//.getTime();

        }
        
        var restInvoice = await dataService.Invoices.listInvoice(-1, mindate, maxdate);
        setListInvoice(restInvoice.data);

        let totalInvoicetmp = 0;
        let restInvoicetmp = 0;
        for (var i = 0; i < restInvoice.data.length; i++) {
            let invoice = restInvoice.data[i];
            totalInvoicetmp += invoice.Montant+ invoice.Acompte;
            if (invoice.Statut === 0)
                restInvoicetmp += invoice.Montant ;

        }
        setRestInvoice(Math.round(restInvoicetmp * 100) / 100);
        setTotalInvoice(Math.round(totalInvoicetmp * 100) / 100);

        setSelectedMonth(month);
        setSelectedYear(year);
    }


    const handleChange = (event: any) => {
        setFilterText(event.target.value);
    }

    const onChangeYear = (event: any) => {
        loadData(parseInt(event.target.value), selectedMonth)
    }

    const onChangeMonth = (event: any) => {
        loadData(selectedYear, parseInt(event.target.value))
    }

    return (
        <div className="container-fluid invoice"  >
            <div className="row">
                <div className="col-xl-12">
                    <div>
                        <div className="filter-header d-flex ">
                            <div className="input-group margright">
                                <input type="text" className="form-control margright" value={filterText} placeholder="Rechercher sur numéro de facture, client" onChange={(event) => handleChange(event)} />

                                <select className="form-control ddl_month margright" onChange={(event) => onChangeMonth(event)} value={selectedMonth} >
                                    <option value="-1">Tous</option>
                                    <option value="1">Janvier</option>
                                    <option value="2">Février</option>
                                    <option value="3">Mars</option>
                                    <option value="4">Avril</option>
                                    <option value="5">Mai</option>
                                    <option value="6">juin</option>
                                    <option value="7">Juillet</option>
                                    <option value="8">Août</option>
                                    <option value="9">Septembre</option>
                                    <option value="10">Octobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Décembre</option>
                                </select>
                                <select className="form-control ddl_year" onChange={(event) => onChangeYear(event)} value={selectedYear} >
                                    <option value="-1">Toutes</option>
                                    {listYear && listYear.map((year, index) => <option key={index} value={year}>{year}</option>)}
                                </select>
                            </div>
                            <NavLink to={'/invoice-edit/0'}>
                                <div className="btn btn-outline-primary ">Créer</div>
                            </NavLink>

                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='margleft'>
                        {resteInvoice>0 && <div className='text-danger'>  Reste à facturer sur la période : {resteInvoice} €</div>}
                        </div>
                        <div className='margright'>
                            Total facturé sur la période : {totalInvoice} €
                        </div>
                        {/* <CreateListInvoice invoiceInfoList={listInvoice} infoPension={infoPension} /> */}
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <tbody>
                                <tr className='header'>
                                    <td className='col-numero'>Numéro</td>
                                    <td className='col-date'>Date</td>
                                    <td>Client</td>
                                    <td className='col-montant'>Montant total</td>
                                    <td className='col-acompte'>Acompte</td>
                                    <td className='col-montant'>Reste à payer</td>
                                    <td className='col-statut'>Statut</td>
                                    <td className='col-action'></td>
                                </tr>
                                {
                                    listInvoice && listInvoice.map((invoice, index) =>
                                        (filterText === null || filterText === undefined
                                            || invoice.Numero.toString().search(filterText) !== -1
                                            || (invoice.NomProprietaire && invoice.NomProprietaire.toString().toLowerCase().search(filterText.toLowerCase()) !== -1)) &&

                                        <tr className='item tr_action' key={index}>
                                            <td className='iscenter'> {invoice.Numero}</td>
                                            <td  >{getDateStr2FromTimeStamp(invoice.Date)}</td>
                                            <td> {invoice.NomProprietaire}</td>
                                            <td className='isdecal'>{invoice.Montant+invoice.Acompte} €</td>
                                            <td className='isdecal'> {invoice.Acompte} €</td>
                                            {invoice.Statut===0&& <td className='isdecal'>{invoice.Montant} €</td>}
                                             {invoice.Statut!==0&& <td className='isdecal'></td>}
                                            <td className='iscenter'>
                                                <div className={`state state-${invoice.Statut}`}>&nbsp;</div>
                                            </td>
                                            <td>
                                                <NavLink to={'/invoice-detail/' + invoice.Id}>
                                                    <i className="fa-solid fa-arrow-right"></i>
                                                </NavLink>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div >
            </div >
        </div >
    )
}
