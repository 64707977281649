import {  useEffect, useState } from "react";

function TimePicker(props: { date: number, onTimeChange: any }) {

    const [heures, setHeures] = useState(0);
    const [minutes, setMinutes] = useState(0);

    useEffect(() => {
        var datetmp = new Date(props.date * 1000);
        setHeures(datetmp.getUTCHours());
        setMinutes(datetmp.getMinutes());

    }, [props.date]);


    const changeHours = async (modif: number) => {
        var hour = heures;
        var min = minutes;
        if (hour + modif >= 24)
            hour = (heures + modif - 24);
        else {
            if (heures + modif < 0)
                hour = (24 - heures + modif);
            else
                hour = (heures + modif);
        }
        refreshTime(hour, min);
    }


    const hourChange = (event: any) => {
        var hour = heures;
        var min = minutes;
        if (Number(event.target.value) > 23)
            hour = (23);
        else if (Number(event.target.value) < 0)
            hour = (0);
        else
            hour = (Number(event.target.value));
        refreshTime(hour, min);
    }
    const minuteChange = (event: any) => {
        var hour = heures;
        var min = minutes;
        if (Number(event.target.value) > 59)
            min = (59);
        else if (Number(event.target.value)< 0)
            min = (0);
        else
            min = (Number(event.target.value));
        refreshTime(hour, min);
    }


    const changeMinute = async (modif: number) => {
        var hour = heures;
        var min = minutes;
        if (minutes + modif >= 60) {
            hour = (heures + 1);
            min = (minutes + modif - 60);
        }
        else {

            if (minutes + modif < 0) {
                hour = (heures - 1);
                min = (60 - minutes + modif);
            }
            else
                min = (minutes + modif);
        }
        refreshTime(hour, min);

    }

    const refreshTime = async (hour: number, minute: number) => {
        var timeTs = hour * 3600 + minute * 60;

        props.onTimeChange(timeTs);
    }

    return (
        <div>
            <div className="timePicker margleft">
                <div className="blockHeure ">
                    <button tabIndex={-1} type="button" className="btn btn-link" onClick={(e) => changeHours(1)}>
                    <i className="fa-solid fa-angle-up" />
                    </button>
                    <input type="text" maxLength={2} inputMode="numeric" className="form-control" placeholder="HH" style={{ textAlign: "center" }} value={heures} onChange={(event) => hourChange(event)} />
                    <button tabIndex={-1} type="button" className="btn btn-link" onClick={(e) => changeHours(-1)}>
                    <i className="fa-solid fa-angle-down" />
                    </button>
                </div>
                <div className="separatorHeure">:</div>
                <div className="blockHeure ">
                    <button tabIndex={-1} type="button" className="btn btn-link" onClick={(e) => changeMinute(5)}>
                    <i className="fa-solid fa-angle-up"/>
                    </button>
                    <input type="text" maxLength={2} inputMode="numeric" className="form-control" placeholder="MM" style={{ textAlign: "center" }} value={minutes} onChange={(event) => minuteChange(event)} />
                    <button tabIndex={-1} type="button" className="btn btn-link" onClick={(e) => changeMinute(-5)}>
                    <i className="fa-solid fa-angle-down" />
                    </button>
                </div>
            </div>
        </div>
    );
}
export default TimePicker;