import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Invoice } from "../../common/models/invoice";
import { Pension } from "../../common/models/pension";
import { Periode } from "../../common/models/periode";
import { Pet } from "../../common/models/pet";
import { Proprio } from "../../common/models/proprio";
import { Tarification } from "../../common/models/tarification";
import { dataService } from "../../services/Services";
import { CreateContract } from "../../utils/GenContractPdf";
import { getDateStr2FromTimeStamp, round2Digit } from "../../utils/Tools";


export default function ProprioGenContrat(props: any) {

    const params = useParams();
    let history = useNavigate();

    
    const { reset, register, handleSubmit } = useForm();
    const [checked, setChecked] = useState(false);
    const [proprioInfo, setProprioInfo] = useState<Proprio>(new Proprio());
    const [infoPension, setInfoPension] = useState(new Pension());
    const [listPet, setListPet] = useState<Pet[]>([]);
    const [listTarif, setListTarif] = useState<Tarification[]>([]);
    const [listPeriode, setListPeriode] = useState<Periode[]>([]);
    const [listPeriodeSelected, setListPeriodeSelected] = useState<number[]>([]);
    const [invoiceInfo, setInvoiceInfo] = useState(new Invoice());
    const [refreshId, setRefreshId] = useState(0);
    const [montantSansRemise, setMontantSansRemise] = useState(0);

    useEffect(() => {
        loadData();
    }, [params]);

    useEffect(() => {
        reset(proprioInfo);
    }, [proprioInfo]);
        
    const loadData = async () => {
        if (params.id !== undefined) {
            if (parseInt(params.id) > 0) {

                var restproprios= await dataService.Proprios.getProprio(parseInt(params.id));
                let proprio = restproprios.data;

                invoiceInfo.ListPets = [];
                invoiceInfo.NomProprietaire = proprio.Nom;
                invoiceInfo.Email = proprio.Email;
                invoiceInfo.Telephone = proprio.NumPortable;
                invoiceInfo.Adresse = proprio.Adresse;
                invoiceInfo.Date = new Date().getTime() / 1000;
                invoiceInfo.Numero = 1;

              
                var restPension = await dataService.Configuration.getInfoPension();
                var restPet = await dataService.Pets.listPetForProprio(proprio.Id);
                var restTarif = await dataService.Tarifications.listTarification(-2);
                var restPeriode = await dataService.Periodes.listPeriodeInvoice(proprio.Id);
                setProprioInfo(proprio)
                setInfoPension(restPension.data);
                setListPet(restPet.data);

                let listTarifTmp = restTarif.data.filter((x: Tarification) => x.Categorie >= 0);

                let listPeriodeTmp = new Array<Periode>();
                for (var i = 0; i < restPeriode.data.length; i++) {
                    let periode = restPeriode.data[i];
                    var tarif = listTarifTmp.find((x: Tarification) => x.Id === periode.TarifId);
                    if (tarif !== undefined) {
                        periode.Montant = tarif.Montant;
                        periode.Acompte = tarif.Tva;
                        periode.MontantDemi = tarif.MontantDemi;
                    }
                    listPeriodeTmp.push(periode);
                }
                setListPeriode(listPeriodeTmp);
                setListTarif(listTarifTmp);
            }
        }
    }

    const changeSelectionPeriode = (periodeId: number) => {
        let listPeriodeSelectedTmp = listPeriodeSelected;
        var indexElt = listPeriodeSelected.indexOf(periodeId);
        if (indexElt > -1)
            listPeriodeSelectedTmp.splice(listPeriodeSelectedTmp.indexOf(periodeId), 1);
        else
            listPeriodeSelectedTmp.push(periodeId);

        setListPeriodeSelected(listPeriodeSelectedTmp);
        let totalContrat = 0;

        invoiceInfo.ListPets = [];
        invoiceInfo.Duree = 0;
        invoiceInfo.NbJour = 0;
        invoiceInfo.NbDemi = 0;
        invoiceInfo.DateDeb = 0;
        invoiceInfo.DateFin = 0;

        listPeriode.forEach((element: Periode) => {
            if (listPeriodeSelectedTmp.indexOf(element.Id) > -1) {
                var tarif = listTarif.find(x => x.Id === element.TarifId);
                if (tarif !== undefined) {
                    totalContrat += (tarif.Montant * (1 + tarif.Tva / 100)) * element.NbJour + (tarif.MontantDemi * (1 + tarif.Tva / 100)) * element.NbDemi;
                    element.Montant = tarif.Montant;
                    element.MontantDemi = tarif.MontantDemi;
                }
                var pet = listPet.find(x => x.Id === element.IdAnimal);
                if (pet !== undefined)
                    invoiceInfo.ListPets.push(pet);
                invoiceInfo.Duree = element.NbJour + (element.NbDemi * 0.5);
                invoiceInfo.NbJour = element.NbJour;
                invoiceInfo.NbDemi = element.NbDemi;
                invoiceInfo.DateDeb = element.DateDeb;
                invoiceInfo.DateFin = element.DateFin;


            }
        });
        setMontantSansRemise(totalContrat);
        invoiceInfo.Montant = round2Digit(totalContrat * (1 - invoiceInfo.Remise / 100));
        setInvoiceInfo(invoiceInfo);
        setRefreshId(refreshId + 1);
        //  this.totalRemise = Math.round((this.totalContrat*(1-this.remise/100))*100)/100 ;
    }

    const updateRemise = async (event: any) => {

        invoiceInfo.Remise = Number(event.target.value);
        invoiceInfo.Montant = round2Digit(montantSansRemise * (1 - Number(event.target.value) / 100));
        setInvoiceInfo(invoiceInfo);
        setRefreshId(refreshId + 1);
    }


    const updateMontantTotal = async (event: any) => {
        setMontantSansRemise(Number(event.target.value));
        invoiceInfo.Montant = round2Digit(Number(event.target.value) * (1 - invoiceInfo.Remise / 100));
        setInvoiceInfo(invoiceInfo);
        setRefreshId(refreshId + 1);
    }

    return (
            <div className="container-fluid margtop bigmargbottom"  >
                <h4>Génération d'un contrat pour {proprioInfo.Nom} </h4>

                <div className="form-group row">
                    <label className="col-12 col-form-label">Choix des périodes à mettre dans le contrat</label>
                </div>
                {listPeriode && listPeriode.map((periode, key) =>
                    <div key={key} className="form-group row margleft" >
                        <div className="col-1">
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" name="chk_check" onChange={(event) => changeSelectionPeriode(periode.Id)} />
                            </div>
                        </div>
                        <div className="col-3">{periode.Nom}</div>
                        <div className="col-3">
                            <div>
                                <span>Du </span>
                                <strong>{getDateStr2FromTimeStamp(periode.DateDeb)}</strong>
                                <br />
                                <span> au </span>
                                <strong>{getDateStr2FromTimeStamp(periode.DateFin)}</strong>
                            </div>
                        </div>
                        <div className="col-2">
                            {periode.NbJour} jour(s)
                            {periode.NbDemi > 0 && <span ><br /> {periode.NbDemi} demi jour(s)</span>}
                        </div>
                        <div className="col-3">
                            {periode.Montant} €/jour {periode.Acompte > 0 && <span > (tva {periode.Acompte}%)</span>}
                            {periode.MontantDemi > 0 && <span ><br /> {periode.MontantDemi} €/demi jour(s)</span>}
                        </div>
                    </div>)}
                <div className="form-group row">
                    <label className="col-3 col-form-label">Remise sur séjour</label>
                    <div className="col-2">
                        <input placeholder="Remise du séjour" type="number" step="0.01" className="form-control" onChange={(e) => updateRemise(e)} defaultValue={invoiceInfo.Remise} />
                    </div>
                    <label className="col-3 col-form-label">%</label>
                </div>
                <div className="form-group row">
                    <label className="col-3 col-form-label">Montant total</label>
                    <div className="col-2">
                        <input placeholder="Montant du séjour" type="number" step="0.01" className="form-control" onChange={(e) => updateMontantTotal(e)} value={montantSansRemise} />
                    </div>
                    <label className="col-3 col-form-label"> €</label>
                </div>
                <div className="form-group row">
                    <label className="col-12 col-form-label">Montant total avec remise <strong> {invoiceInfo.Montant}</strong> €</label>

                </div>
                <div className="d-flex justify-content-end margtop margbottom">
                    <CreateContract infoPension={infoPension} invoiceInfo={invoiceInfo} />
                    <div className="btn btn-outline-secondary margright" onClick={() => history(-1)} >Annuler</div>
                </div>
            </div>      
    );

}