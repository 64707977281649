import  { Page, Text, View, Document, StyleSheet,  PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import {
  Style
} from '@react-pdf/types';

import { Invoice, InvoiceInfoTva, InvoiceItem } from '../common/models/invoice';
import { Pension } from '../common/models/pension';
import { getDateStr2FromTimeStamp, getDateStrFileFromTimeStamp, getPaimentLib } from './Tools';
Font.register({
  family: 'Roboto',
  fonts: [
  { src: '/webfonts/Roboto-Regular.ttf' },
 
  ]
  });

// Create styles
const borderColor = '#aaa';
const backEnteteColor = '#dddddd';
const heightCell = '18px'
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: 10,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  spacebetween: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  title: {
    fontSize: 16,

  },
  cellsurbrillance: {
    fontSize: 9,
    fontWeight: 600,
    height: heightCell,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: backEnteteColor,
    borderRightWidth: 1,
    borderRightColor: borderColor,
  },
  cell: {

    height: heightCell,
    flexDirection: 'column',
    justifyContent: 'center',
    borderRightWidth: 1,
    borderRightColor: borderColor,
  },
  tableListService: {
    marginLeft: '20px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderLeftColor: borderColor,
    borderBottomColor: borderColor,
    width: '93%',
  },
  rowEntete: {
    flexDirection: 'row',
    backgroundColor: backEnteteColor,
    height: heightCell,
  },
  row: {
    flexDirection: 'row',
    fontSize: 8,
    height: heightCell,
  },
  rowBorder: {
    flexDirection: 'row',
    borderTopColor: borderColor,
    borderTopWidth: 1,
    fontSize: 8,
    height: heightCell,
  },
  description: {
    width: '53%',
  },
  descriptioncat: {
    width: '53%',
    fontSize: '10px',
  },
  qte: {
    width: '8%',
  },
  prixU: {
    width: '8%',

  },
  remise: {
    width: '8%',

  },
  tva: {
    width: '8%',
  },
  total: {
    width: '15%',
  },

  tableList200: {

    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderTopColor: borderColor,
    borderLeftColor: borderColor,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    width: "200px",
  },
  taux: {
    width: '30%',
    textAlign: 'center',
  },
  baseht: {
    width: '30%',
    textAlign: 'center',
  },
  montanttva: {
    width: '40%',
    textAlign: 'center',
  },
  libtotal: {
    width: '60%',
  },
  libtotalBorder: {
    width: '60%',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
  },
  montanttotal: {
    width: '40%',
  },
  logo: {
    height: '80px',
    maxWidth: '250px',
  },

});

export function CreateListInvoice(props: { infoPension: Pension, invoiceInfoList: Invoice[] }) {
  let fileName='Facture-aaa.pdf'
   let MyDocument =
     <Document>
      {props.invoiceInfoList && props.invoiceInfoList.map((invoiceInfo,index)=>
      (invoiceInfo &&
       <Page size="A4" style={styles.page} key={index}>
         <View>
           <BillFrom infoPension={props.infoPension} />
           <BillTo invoiceInfo={invoiceInfo} />
           <ListService invoiceInfo={invoiceInfo} />
           <TotalInvoice invoiceInfo={invoiceInfo} />
         </View>
         <MentionLegale infoPension={props.infoPension} />
       </Page>))}
     </Document>;
   return (
     <PDFDownloadLink document={MyDocument}   fileName={fileName}>
        {({ url, loading, error }) => {
         
             if (loading) {
               return (
                 <span>
                   génération facture...
                 </span>
               );
             }
             if (!loading && url) {
             
               return (
                
                   <div className="btn btn-outline-primary margright " >Générer Facture</div>
                
               );
             }
             if (error) {
               console.error(error);
               return <p>une erreur est survenue</p>;
             }
             return null;
           }}
     </PDFDownloadLink>  
     )
 }
 
 

export function CreateInvoice(props: { infoPension: Pension, invoiceInfo: Invoice }) {
 let fileName='Facture-' + props.invoiceInfo.Numero + '-' + props.invoiceInfo.NomProprietaire + '-' + getDateStrFileFromTimeStamp(props.invoiceInfo.Date) + '.pdf'
  let MyDocument =
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <BillFrom infoPension={props.infoPension} />
          <BillTo invoiceInfo={props.invoiceInfo} />
          <ListService invoiceInfo={props.invoiceInfo} />
          <TotalInvoice invoiceInfo={props.invoiceInfo} />
        </View>
        <MentionLegale infoPension={props.infoPension} />
      </Page>
    </Document>;
  return (
    <PDFDownloadLink document={MyDocument}   fileName={fileName}>
       {({ url, loading, error }) => {
        
            if (loading) {
              return (
                <span>
                  génération facture...
                </span>
              );
            }
            if (!loading && url) {
            
              return (
               
                  <div className="btn btn-outline-primary margright " >Générer Facture</div>
               
              );
            }
            if (error) {
              console.error(error);
              return <p>une erreur est survenue</p>;
            }
            return null;
          }}
    </PDFDownloadLink>  
    )
}


const BillFrom = (props: { infoPension: Pension }) => (
  <View style={[styles.spacebetween, { marginTop: '20px' }]} >
    <View style={{ marginLeft: '20px' }}>
      <Text style={styles.title}>{props.infoPension.Nom}</Text>
      <Text>{props.infoPension.Adresse}</Text>
      <Text>Tél. : {props.infoPension.NumTel}</Text>
      <Text>Tél. portable : {props.infoPension.NumTel}</Text>
      <Text>Email : {props.infoPension.Email}</Text>
    </View>
    <View style={{ marginRight: '20px' }} >
      {props.infoPension.Logo && <Image style={styles.logo} src={props.infoPension.Logo}   />}
    </View>
  </View>
);
const BillTo = (props: { invoiceInfo: Invoice }) => (

  <View style={[styles.spacebetween, { marginTop: '20px' }]}>
    <View style={{ marginLeft: '20px' }}>
      <Text >FACTURE N° {props.invoiceInfo.Numero}</Text>
      <Text>Date : {getDateStr2FromTimeStamp(props.invoiceInfo.Date)}</Text>
    </View>
    <View style={{ marginRight: '50px' }}>
      <Text >{props.invoiceInfo.NomProprietaire}</Text>
      <Text>{props.invoiceInfo.Adresse}</Text>
      <Text>Tél. : {props.invoiceInfo.Telephone}</Text>
      <Text>Email : {props.invoiceInfo.Email}</Text>
    </View>
  </View>
);


const ListService = (props: { invoiceInfo: Invoice }) => (
  <View style={styles.tableListService}>
    <View style={styles.row}>
      <CellSurbillance text='Description' style={styles.description} />

      <CellSurbillance style={styles.qte} text='Qté' />
      <CellSurbillance style={styles.prixU} text='P.U. HT' />
      <CellSurbillance style={styles.remise} text='Remise' />
      <CellSurbillance style={styles.total} text='Montant HT' />
      <CellSurbillance style={styles.tva} text='TVA' />
    </View>
    {props.invoiceInfo.ListDetail.findIndex(x => x.Type === 0) >= 0 &&
      <View style={styles.row}>
        <Cell style={styles.descriptioncat} text="PENSION" left={true} />
        <Cell style={styles.qte} text="" />
        <Cell style={styles.prixU} text="" />
        <Cell style={styles.remise} text="" />
        <Cell style={styles.total} text="" />
        <Cell style={styles.tva} text="" />
      </View>}
    {props.invoiceInfo.ListDetail.map((item,key) =>
    (item.Type === 0 &&
      <LineInvoice key={key} invoiceItem={item} />
    ))

    }
    {props.invoiceInfo.ListDetail.findIndex(x => x.Type === 1) >= 0 &&
      <View>
        {props.invoiceInfo.ListDetail.findIndex(x => x.Type === 0) >= 0 && <View style={styles.row}>
          <Cell style={styles.descriptioncat} text="" left={true} />
          <Cell style={styles.qte} text="" />
          <Cell style={styles.prixU} text="" />
          <Cell style={styles.remise} text="" />
          <Cell style={styles.total} text="" />
          <Cell style={styles.tva} text="" />
        </View>}
        <View style={styles.row}>
          <Cell style={styles.descriptioncat} text="AUTRES" left={true} />
          <Cell style={styles.qte} text="" />
          <Cell style={styles.prixU} text="" />
          <Cell style={styles.remise} text="" />
          <Cell style={styles.total} text="" />
          <Cell style={styles.tva} text="" />
        </View></View>}
    {props.invoiceInfo.ListDetail.map((item,key) => (
      item.Type === 1 &&
      <LineInvoice key={key} invoiceItem={item} />
    ))
    }
  </View>
);

const LineInvoice = (props: { invoiceItem: InvoiceItem }) => (
  <View style={styles.row}>
    <Cell style={styles.description} text={`${props.invoiceItem.Libelle}`} left={true} />
    <Cell style={styles.qte} text={`${props.invoiceItem.Nombre}`} />
    <Cell style={styles.prixU} text={`${props.invoiceItem.MontantU} €`} />
    <Cell style={styles.remise} text={`${props.invoiceItem.Remise} %`} />
    <Cell style={styles.total} text={`${Math.round(((props.invoiceItem.MontantU * props.invoiceItem.Nombre) * (1 - props.invoiceItem.Remise / 100)) * 100) / 100 + ' €'}`} />
    <Cell style={styles.tva} text={`${props.invoiceItem.Tva}`} />
  </View>
);

const TotalInvoice = (props: { invoiceInfo: Invoice }) => {
  let listTVA = new Array<InvoiceInfoTva>();
  let totalPensionHT = 0;
  let totalAutreHT = 0;
  let totalTVA = 0;
  for (var i = 0; i < props.invoiceInfo.ListDetail.length; i++) {
    let curObj = props.invoiceInfo.ListDetail[i];
    let tva = listTVA.find(x => x.Taux === curObj.Tva);
    if (!tva) {
      tva = new InvoiceInfoTva();
      tva.Taux = curObj.Tva;
      listTVA.push(tva);
    }
    tva.BaseHT += Math.round(((curObj.MontantU * curObj.Nombre) * (1 - curObj.Remise / 100)) * 100) / 100;
    if (curObj.Type === 0)
      totalPensionHT += Math.round(((curObj.MontantU * curObj.Nombre) * (1 - curObj.Remise / 100)) * 100) / 100;
    else if (curObj.Type === 1)
      totalAutreHT += Math.round(((curObj.MontantU * curObj.Nombre) * (1 - curObj.Remise / 100)) * 100) / 100;
    totalTVA += (Math.round(((curObj.MontantU * curObj.Nombre) * (1 - curObj.Remise / 100)) * 100) / 100) * (curObj.Tva / 100);
  }
  return (
    <View style={styles.spacebetween}>
      <View>
        <View style={[styles.tableList200, { marginLeft: 20 }]}>
          <View style={styles.row}>
            <CellSurbillance style={{ width: '30%' }} text="Taux TVA" />
            <CellSurbillance style={{ width: '30%' }} text="Base HT" />
            <CellSurbillance style={{ width: '40%' }} text="Montant TVA" />
          </View>
          {listTVA && listTVA.map((item,key) =>
            <View key={key} style={styles.row}>
              <Cell style={{ width: '30%' }} text={`${item.Taux}`} />
              <Cell style={{ width: '30%' }} text={`${item.BaseHT} €`} />
              <Cell style={{ width: '40%' }} text={`${item.BaseHT * (item.Taux / 100)} €`} />
            </View>)
          }
        </View>
        {props.invoiceInfo.PaiementType > 0 && <View style={[styles.tableList200, { marginLeft: 20 }]}>
          <View style={styles.row}>
            <CellSurbillance style={{ width: '100%' }} text="Détail réglement" />
          </View>
          <View style={styles.row}>
            <Cell style={{ width: '30%' }} text={`${getDateStr2FromTimeStamp(props.invoiceInfo.PaiementDate)}`} />
            <Cell style={{ width: '30%' }} text={`${props.invoiceInfo.Montant} €`} />
            <Cell style={{ width: '40%' }} text={`${getPaimentLib(props.invoiceInfo.PaiementType)}`} />
          </View>
        </View>}
        </View>
      <View>
      <View style={[styles.tableList200, { marginRight: 20 }]}>
        {totalPensionHT > 0 && <View style={styles.row}>
          <CellSurbillance text="Total pension HT" style={styles.libtotal} />
          <Cell style={styles.montanttotal} text={`${totalPensionHT} €`} />
        </View>}
        {props.invoiceInfo.Remise > 0 && totalPensionHT > 0 && <View style={styles.row}>
          <CellSurbillance text="Remise pension" style={styles.libtotal} />
          <Cell style={styles.montanttotal} text={`- ${Math.round(totalPensionHT * props.invoiceInfo.Remise / 100 * 100) / 100} € (${props.invoiceInfo.Remise}%)`} />
        </View>}
        {totalAutreHT > 0 && <View style={styles.row}>
          <CellSurbillance text="Total autre HT" style={styles.libtotal} />
          <Cell style={styles.montanttotal} text={`${totalAutreHT} €`} />
        </View>}
        <View style={styles.row}>
          <CellSurbillance text="Total TVA" style={styles.libtotal} />
          <Cell style={styles.montanttotal} text={`${totalTVA} €`} />
        </View>
        <View style={styles.row}>
          <CellSurbillance text="Total TTC" style={styles.libtotal} />
          <Cell style={styles.montanttotal} text={`${totalPensionHT + totalAutreHT + totalTVA} €`} />
        </View>
        {props.invoiceInfo.Acompte > 0 && <View style={styles.row}>
          <CellSurbillance text={`Acomptes (${getPaimentLib(props.invoiceInfo.AcompteType)})`} style={styles.libtotal} />
          <Cell style={styles.montanttotal} text={`- ${props.invoiceInfo.Acompte} €`} />
        </View>}
        {props.invoiceInfo.Debours > 0 && <View style={styles.row}>
          <CellSurbillance text="Débours" style={styles.libtotal} />
          <Cell style={styles.montanttotal} text={`${props.invoiceInfo.Debours} €`} />
        </View>}
        <View style={styles.rowBorder}>
          <CellSurbillance text="Net à payer" style={styles.libtotalBorder} />
          <Cell style={styles.montanttotal} text={`${props.invoiceInfo.Montant} €`} />
        </View>
        </View>
      </View>
    </View>
  )
};

const MentionLegale = (props: { infoPension: Pension }) => (
  <View style={{ marginLeft: '20px', marginBottom: '20px' }} >
    <Text> {props.infoPension.MentionsLegales}</Text>

  </View>
);

const Cell = (props: { text: string, style: Style, left?: boolean }) => (
  <View style={[styles.cell, props.style]}>
    <Text style={{ textAlign: props.left ? 'left' : 'center', textIndent: props.left ? '5px' : '0px' }}>{props.text}</Text>
  </View>
);

const CellSurbillance = (props: { text: string, style: Style }) => (
  <View style={[styles.cellsurbrillance, props.style]}>
    <Text style={{
      textAlign: 'center',
      fontWeight: 600
    }}>{props.text}</Text>
  </View>
);

