import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DlgDelete from "../../common/dialog/DlgDelete";
import DlgPetEdit from "../../common/dialog/DlgPetEdit";
import { Pet } from "../../common/models/pet";
import { dataService } from "../../services/Services";
import ProprioPetItem from "./ProprioPetItem";

export default function ProprioPetList(props: any) {
    const [listPet, setListPet] = useState<Pet[]>([])
    const [modeVue, setModeVue] = useState("display");
    const [selectPet, setSelectPet] = useState<Pet>(new Pet())

    useEffect(() => {
        loadData();
    }, [props.proprioId]);

    const loadData = async () => {
        dataService.Pets.listPetForProprio(props.proprioId).then((response) => { setListPet(response.data); });
    }    

    const openDlgEdit = async (pet:Pet) => {
        setSelectPet(pet);
        setModeVue("edit");
    }

        

    const openDlgDelete = async (pet:Pet) => {
        setSelectPet(pet);
        setModeVue("delete");
    }

    const openDlgNew = async () => {

        let newPet = new Pet();
        newPet.Id = -1;
        newPet.Type = -1;
        newPet.Sexe = 0;
        newPet.IdProprietaire =props.proprioId;
        newPet.Nom = "";
        newPet.DateLastVaccin = new Date().getTime() / 1000;//TODO : a définir
        newPet.DateNaissance = new Date().getTime() / 1000//TODO : a définir
        newPet.HasSterilise = false;
        newPet.HasTraitement = false;
    
        newPet.Identification = "";
        newPet.IsDCD = false;
        newPet.Particularite = "";
        newPet.Race = "";
        newPet.Traitement = "";
        newPet.Provenance = "";
    
        newPet.Robe = "";
        newPet.Vaccin = "";
        setSelectPet(newPet);   
        setModeVue("edit");
    }

    
  const onCloseDlg = async () => {   
    setModeVue("display");
  }
 
  const updatePet = async (pet:Pet) => {
    dataService.Pets.savePet(pet).then((response) => { loadData(); });
    onCloseDlg();
  }

  const deletePet = async () => {
    dataService.Pets.deletePet(selectPet.Id).then((response) => { loadData(); });
    onCloseDlg();
  }

  

    return (
        <div className="card  b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Animaux</h3>
                </div>
                <div className="card-body ">
                    {listPet && listPet.map((pet) =>
                        <ProprioPetItem key={pet.Id} pet={pet}  onEditPet={openDlgEdit} onDeletePet={openDlgDelete}/>
                    )}
                    <hr className="d-print-none" />
                    <div className="card-action clearfix">
                    <NavLink to={'/pet-edit/'+props.proprioId+'/0'}>
                            <div className="btn btn-outline-primary">Ajouter</div>
                        </NavLink>
                      
                    </div>
                </div>
            </div>
            { modeVue==="edit" && <DlgPetEdit onClose={onCloseDlg}   pet={selectPet} onValid={updatePet} /> }
             { modeVue==="delete" && <DlgDelete onClose={onCloseDlg}  libelle={selectPet.Nom} onDelete={deletePet} /> }
        </div >
    );
}
