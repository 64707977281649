import React, { useEffect, useState } from 'react';
import DlgDelete from '../../common/dialog/DlgDelete';
import { Proprio } from '../../common/models/proprio';
import { dataService } from '../../services/Services';
import { NavLink, useNavigate } from 'react-router-dom';
import DlgProprioEdit from '../../common/dialog/DlgProprioEdit';
import DlgContratGen from '../../common/dialog/DlgContratGen';

export default function ProprioInfo(props: any) {
    const [proprioInfo, setProprioInfo] = useState(new Proprio())
    const [modeVue, setModeVue] = useState("display");
    let history = useNavigate();

    useEffect(() => {
        loadData();

    }, [props.proprioId]);

    const loadData = async () => {
        dataService.Proprios.getProprio(props.proprioId).then((response) => { setProprioInfo(response.data); });
    }
    const onCloseDlg = async () => {
        setModeVue("display");
    }

    const openDlgDelete = async () => {
        setModeVue("delete");
    }

    const openDlgEdit = async () => {
        setModeVue("edit");
    }

    const openDlgContrat = async () => {
        setModeVue("contrat");
    }

    const onDelete = async () => {
        dataService.Proprios.deleteProprio(proprioInfo.Id).then((response) => { onCloseDlg(); history('/proprio'); });
    }

    const onValid = async (proprio: Proprio) => {
        dataService.Proprios.saveProprio(proprio).then((response) => { onCloseDlg(); loadData(); });
    }

    return (

        <div className="card b">

            <div className="card-content ">
                <div className="card-header">
                    <h3><span>{proprioInfo.Nom}</span></h3>
                </div>
                <div className="card-body">
                    <form className="form-horizontal">
                        <div className="form-group row">
                            <div className="col-md-4">Nom</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.Nom}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Adresse</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.Adresse}</strong>
                            </div>
                        </div>


                        <div className="form-group row">
                            <div className="col-md-4">Téléphone</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.NumTel}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Email</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.Email}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Portable</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.NumPortable}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Portable 2</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.NumPortable2}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Autre contact nom</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.AutreContactName}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Autre contact téléphone</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.AutreContactTel}</strong>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-4">Nous a connu par</div>
                            <div className="col-md-8">
                                <strong>{proprioInfo.ConnuPar}</strong>
                            </div>
                        </div>
                    </form>

                    <hr className="d-print-none" />
                    <div className="card-action clearfix">
                        <button type="button" className="btn btn-outline-danger" onClick={openDlgDelete}  >Supprimer</button>
                        <NavLink to={'/proprio-edit/' + proprioInfo.Id}>
                            <div className="btn btn-outline-primary">Modifier</div>
                        </NavLink>
                        <NavLink to={'/proprio-gen-contrat/' + proprioInfo.Id}>
                            <div className="btn btn-outline-primary">Générer contrat</div>
                        </NavLink>
                       
                    </div>
                </div>
            </div>

            {modeVue === "delete" && <DlgDelete onClose={onCloseDlg} libelle={proprioInfo.Nom} onDelete={onDelete} />}
            {modeVue === "edit" && <DlgProprioEdit onClose={onCloseDlg} proprio={proprioInfo} onValid={onValid} />}
            {modeVue === "contrat" && <DlgContratGen onClose={onCloseDlg} proprioInfo={proprioInfo} onValid={onValid} />}
        </div>
    );
}