import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Periode } from '../../common/models/periode';
import { getDateStr2FromTimeStamp, getDateTimeStrFromTimeStamp, getTimeStrFromTimeStamp } from '../../utils/Tools';

export default function PlanningItem(props: { periode: Periode, onEditPeriode: any }) {
   
    const [tooltip, setTooltip] = useState("");
    React.useEffect(() => {
        var infotootip = "";
        infotootip += props.periode.Nom + " (" + props.periode.NomProprietaire + ")<br/>";
        if(getDateStr2FromTimeStamp(props.periode.DateDeb)===getDateStr2FromTimeStamp(props.periode.DateFin)){
            infotootip += getDateTimeStrFromTimeStamp(props.periode.DateDeb) + " à " + getTimeStrFromTimeStamp(props.periode.DateFin);
        }
        else{
            infotootip += getDateTimeStrFromTimeStamp(props.periode.DateDeb) + " au " + getDateTimeStrFromTimeStamp(props.periode.DateFin);
        }
        setTooltip(infotootip);
    }, [props]);

    const openEditPeriode = async () => {
        props.onEditPeriode(props.periode);
    }

    return (
        <div>
            <div className="planning_item " data-tip={tooltip} data-for={'tt_etat' + props.periode.Id}
                onClick={openEditPeriode}
                style={{ backgroundColor: props.periode.Color, color: 'black', position: 'absolute', width: props.periode.Width + 'px', height: '46px', left: props.periode.Left + 'px', top: props.periode.Top + 'px' }}>
                {props.periode.Nom}({props.periode.NomProprietaire})<br />
                {getDateTimeStrFromTimeStamp(props.periode.DateDeb)} au {getDateTimeStrFromTimeStamp(props.periode.DateFin)}
            </div>
            <ReactTooltip id={'tt_etat' + props.periode.Id}
                html={true}
                effect='solid'
                place={'top'}
            /></div>

    );

}
