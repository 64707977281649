import {  useEffect } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Proprio } from "../models/proprio";


function DlgVetoEdit(props: { veto: Proprio, onClose: any, onValid: any }) {


    const { register,  handleSubmit } = useForm();
   
  
    useEffect(() => {
        if (props.veto !== undefined) {
           
        }
    }, [props.veto]);



    const onSubmit = (data: any) => {
        
        var proprio = data;
        proprio.Id = props.veto.Id;
        props.onValid(proprio);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    return (
        <Dialog
            title="Information vétérinaire"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>

            <div className="dlg_proprio_edit">

            <div className="form-group row">
          <label className="col-3 col-form-label">Nom</label>
          <div className="col-9">
            <input placeholder="Nom"  type="text" className="form-control"  {...register("VetoName", { required: false })} defaultValue={props.veto.VetoName} />
             
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Adresse</label>
          <div className="col-9">
            <textarea placeholder="Adresse"  className="form-control note-editor" rows={4}  {...register("VetoAdresse", { required: false })} defaultValue={props.veto.VetoAdresse} />
             
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Téléphone</label>
          <div className="col-9">
            <input placeholder="Téléphone"  type="text" className="form-control"  {...register("VetoNumTel", { required: false })} defaultValue={props.veto.VetoNumTel} />
             
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Téléphone 2</label>
          <div className="col-9">
            <input placeholder="Téléphone 2"  type="text" className="form-control" {...register("VetoNumTel2", { required: false })} defaultValue={props.veto.VetoNumTel2} />
              
          </div>
        </div>
            <div className="ligne">
                <div id="editEtatmessageError" className="text-danger"></div>
            </div>
        </div >

        </Dialog >
    );
}
export default DlgVetoEdit;