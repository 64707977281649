import { useState, useEffect } from "react";
import { NavLink,   } from 'react-router-dom'
import { Periode } from "../../common/models/periode";
import { dataService } from "../../services/Services";
import { renderImgAnimal, renderInOut } from "../../utils/Tools";

function  Movement( props:any ) {
    const [movements, setMovements] = useState<Periode[]>([]);
    useEffect(() => {
     
        dataService.Periodes.listMoveDay(props.selectedDate).then((response) => { setMovements(response.data) });

    }, [props.selectedDate]);

    return (
        <div className="card b">
            <div className="card-content ">
                <div className="card-header">
                    <h3>Mouvements</h3>
                </div>
                <div className="card-body limit500">

                    {movements !== undefined && movements.map((mouvement) => (
                        <div key={mouvement.Id}>
                            <img src={renderInOut(mouvement.TypeMouvement)} alt="type" />
                            <img src={renderImgAnimal(mouvement.TypeAnimal)} alt="cat" />
                            <NavLink to={'/pet/' + mouvement.TypeAnimal + '/' + mouvement.IdAnimal}>
                                <span className='pointerBold'   >
                                    {mouvement.Nom}
                                </span>
                            </NavLink>
                            <NavLink to={"/proprio/" + mouvement.IdProprietaire}>
                                <span className='pointerBold'  >
                                    &nbsp;({mouvement.NomProprietaire})
                                </span>
                            </NavLink>                           
                            <span> {mouvement.NomBox}</span>
                            <span> à </span>
                            <span className="{plnTools.renderClassHour(mouvement.TypeMouvement)}">
                                {mouvement.HeureMouvement}
                            </span>
                        </div>
                        
                    ))}
                    {(movements === undefined || movements.length === 0) && <div > Aucun mouvement</div>
                    }
                </div>
            </div>
        </div>);

};
export default Movement;
