export class PlanningEvent {
    DateDeb: number=0;
    DateFin: number=0;
    DateDebPlan: number=0;
    DateFinPlan: number=0;
    HeureMouvement: string="";
    Id: number=0;
    Description: string="";
    TypeEvent: number=0;
   

    Left : number=0;
    Width:number=0;
    LeftPercent : number=0;
    WidthPercent:number=0;
    Top :number=0;
    Line :number=0;
    Color:string="";
    Duree : number=0;
   
    Position:number=0;
    heureDeb: number=0;
    heureFin: number=0;

    IsSelected : boolean=false;   

    
}
