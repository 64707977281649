export class InfoDayCalendar {
    Jour: number=0;
    Month: number=0;
    Year:number=0;
    NbDay:number=0;
    Posleft:number=0;
    LeftPercent : number=0;
    Width:number=0;
    Height:number=0;
    ClassDay : string="";
    NbPet :number=0;
}
