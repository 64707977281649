import React, { useState } from 'react';
import ProprioInfo from './ProprioInfo';
import ProprioPeriodeList from './ProprioPeriodeList';
import ProprioPetList from './ProprioPetList';
import ProprioVeto from './ProprioVeto';
import { useNavigate, useParams } from 'react-router-dom';
import { dataService } from '../../services/Services';
import { Proprio } from '../../common/models/proprio';
import DlgProprioEdit from '../../common/dialog/DlgProprioEdit';
import { SelectProprio } from '../../components/SelectProprio';

const ProprioPage = () => {
    const [selectedProprioId, setSelectedProprioId] = useState(0);
    const params = useParams();
    const [modeVue, setModeVue] = useState("display");
    const [newProprio, setNewProprio] = useState(new Proprio());

    let history = useNavigate();

    React.useEffect(() => {

        let proprioTmp = new Proprio();
        proprioTmp.Id = -1;
        proprioTmp.Adresse = "";
        proprioTmp.IsDCD = false;
        proprioTmp.NumPortable = "";
        proprioTmp.NumPortable2 = "";
        proprioTmp.NumTel = "";
        proprioTmp.AutreContactName = "";
        proprioTmp.AutreContactTel = "";
        proprioTmp.Email = "";
        proprioTmp.Nom = "";
        proprioTmp.ConnuPar = "";
        setNewProprio(proprioTmp);

        if (params.proprioId !== undefined)
            setSelectedProprioId(parseInt(params.proprioId));

    }, [params]);


    const onSelectProprio = async (selectedId: number) => {
        history('/proprio/' + selectedId)
    }

    const openDlgNew = async () => {
        setModeVue("new");
    }


    const onCloseDlg = async () => {
        setModeVue("display");
    }

    const onValid = async (proprio: Proprio) => {
        dataService.Proprios.saveProprio(proprio).then((response) => { onCloseDlg(); history('/proprio/' + response.data.IdResult); });
    }


    if (selectedProprioId > 0) {
        return (

            <div>
                <div >
                    <div >
                        <div className="filter-header d-flex justify-content-end" >
                            <SelectProprio onSelect={onSelectProprio} defaultProprioId={selectedProprioId} />
                            <div className="btn btn-outline-primary margleft" onClick={openDlgNew}>Créer</div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid"  >
                    <div className="row " >
                        <div className="col-xl-9">
                            <div className="row ">
                                <div className="col-xl-7">
                                    <ProprioInfo proprioId={selectedProprioId} />
                                </div>
                                <div className="col-xl-5">
                                    <ProprioVeto proprioId={selectedProprioId} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <ProprioPeriodeList proprioId={selectedProprioId} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <ProprioPetList proprioId={selectedProprioId} />
                        </div>
                    </div>
                </div>
                {modeVue === "new" && <DlgProprioEdit onClose={onCloseDlg} proprio={newProprio} onValid={onValid} />}
            </div>
        );
    } else
        return (
            <div>
                {/* <div>Propriétaire n'existe pas</div>
                <NavLink to={'/proprio'}>
                <span className='pointer text-bold' >Retour à la liste </span>
                    
                </NavLink> */}
            </div>
        )
}
export default ProprioPage;