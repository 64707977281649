import {  useEffect, useState } from "react";
import { Dialog } from "../../components/Dialog";
import { useForm } from "react-hook-form";
import { Pet } from "../models/pet";
import { dataService } from "../../services/Services";
import { TypePet } from "../models/type-pet";
import DatePicker from "react-datepicker";
import { getDateFromTimeStamp } from "../../utils/Tools";


function DlgPetEdit(props: { pet: Pet, onClose: any, onValid: any }) {

     const { register,setValue,  handleSubmit } = useForm();
    const [listType, setListType] = useState<TypePet[]>([]);
    const [checkedTraitement, setCheckedTraitement] = useState(false); 
    const [checkedSterile, setCheckedSterile] = useState(false); 
    const [checkedDcd, setCheckedDcd] = useState(false); 
    const [selectedDateNaissance, setSelectedDateNaissance] = useState(new Date());
    useEffect(() => {
        if (props.pet !== undefined) {

          loadData();
           

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.pet]);
   


  const loadData = async () => {
    var restpetType = await dataService.Configuration.getListCategorie();
    setListType(restpetType.data);
    setValue("Type", props.pet.Type);

      setCheckedDcd(props.pet.IsDCD);
      setCheckedTraitement(props.pet.HasTraitement);
      setCheckedSterile(props.pet.HasSterilise);
      setSelectedDateNaissance(getDateFromTimeStamp(props.pet.DateNaissance));
  }

    const onSubmit = (data: any) => {
        
        var pet = data;
        pet.Id = props.pet.Id;
        pet.IdProprietaire = props.pet.IdProprietaire;
        pet.IsDCD=checkedDcd;
        pet.HasSterilise=checkedSterile
        pet.HasTraitement=checkedTraitement
        pet.DateNaissance =   (selectedDateNaissance.getTime()) / 1000 ;
        pet.Vaccin = props.pet.Vaccin;
        pet.DateLastVaccin= props.pet.DateLastVaccin;
        if(pet.Traitement === undefined)
        pet.Traitement= props.pet.Traitement;
        props.onValid(pet);
    }

    const onCloseDlg = async () => {

        props.onClose();
    }

    const handleChangeTraitement = () => { 
    
        setCheckedTraitement(!checkedTraitement); 
        
      };
      const handleChangeSterile = () => { 
    
        setCheckedSterile(!checkedSterile); 
        
      };
      const handleChangeDcd = () => { 
    
        setCheckedDcd(!checkedDcd); 
        
      };
      
    const setDateNaissance = (newDate: any) => {
      if (newDate != null) {
          setSelectedDateNaissance(newDate);
      }
  }

    return (
        <Dialog
            title="Information animal"
            onClose={onCloseDlg}
            onSubmit={handleSubmit(onSubmit)}
            actionbutton={
                <div>
                    <input className="btn btn-outline-primary margright" type="submit" value="Valider" />
                    <div onClick={onCloseDlg} className="btn btn-outline-secondary" >Annuler</div>
                </div>
            }>

            <div className="dlg_pet_edit">
            <div className="form-group row">
              <label className="col-3 col-form-label">Nom</label>
              <div className="col-9">
                <input placeholder="Nom"  type="text" className="form-control"  {...register("Nom", { required: false })} defaultValue={props.pet.Nom} />
                
              </div>
            </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Type</label>
          <div className="col-9">
            <select className="form-control"  {...register("Type", { required: false })} defaultValue={props.pet.Type} >
               { listType && listType.map((item)=>(   <option key={item.Id} value={item.Id}>{item.Libelle}</option>))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Sexe</label>
          <div className="col-9">
            <select className="form-control"   {...register("Sexe", { required: false })} defaultValue={props.pet.Sexe} > 
              <option value='0'>Male</option>
              <option value='1'>Femelle</option>
            </select>
          </div>
        </div>

        
        <div className="form-group row">
          <label className="col-3 col-form-label">Date naissance</label>
          <div className="col-9">       
          <DatePicker selected={selectedDateNaissance} className="form-control" onChange={(date) => setDateNaissance(date)} dateFormat="dd/MM/yyyy" />   
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Race</label>
          <div className="col-9">          
            <input placeholder="Race" type="text" className="form-control" {...register("Race", { required: false })} defaultValue={props.pet.Race} />
            
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Robe</label>
          <div className="col-9">
            <input placeholder="Robe"  type="text" className="form-control"  {...register("Robe", { required: false })} defaultValue={props.pet.Robe} />
          
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Identification</label>
          <div className="col-9">
            <input placeholder="Identification" type="text" className="form-control"   {...register("Identification", { required: false })} defaultValue={props.pet.Identification} />
 
          </div>
        </div>
        <div className="form-group row">
            <label className="col-3 col-form-label">Provenance</label>
            <div className="col-9">
              <input placeholder="Provenance"  type="text" className="form-control" {...register("Provenance", { required: false })} defaultValue={props.pet.Provenance} />
                
            </div>
          </div>
        <div className="form-group row">
          <label className="col-3 col-form-label">Particularité</label>
          <div className="col-xl-9">
            <textarea placeholder="Particularité de l'animal"  className="form-control note-editor" rows={4} {...register("Particularite", { required: false })} defaultValue={props.pet.Particularite} />
            
          </div>
        </div>
      
        <div className="form-group row">
          <div className="col-4">
          <div className="form-group form-check">
                        <input  type="checkbox"  className="form-check-input"  checked={checkedTraitement} {...register('HasTraitement', { required: false })} onChange={handleChangeTraitement}   />
                        <label className="form-check-label">Traitement en cours</label>
                    </div>
                                  
            </div>
              <div className="col-4">
                <div className="form-group form-check">
                <input  type="checkbox"  className="form-check-input"  checked={checkedSterile} {...register('HasSterilise', { required: false })} onChange={handleChangeSterile}   />
                 <label className="form-check-label" >Est stérilisé</label>
                </div>      
            </div>
            <div className="col-4">
              <div className="form-group form-check">
              <input  type="checkbox"  className="form-check-input"  checked={checkedDcd} {...register('IsDCD', { required: false })} onChange={handleChangeDcd}   />
                <label className="form-check-label" >Est décédé</label>
              </div>                        
           </div>          
        </div>
        {checkedTraitement &&<div className="form-group row">
          <label className="col-3 col-form-label">Traitement</label>
          
          <div className="col-9">
              <textarea placeholder="Indication sur le traitement" className="form-control note-editor" rows={4}  {...register("Traitement", { required: false })} defaultValue={props.pet.Traitement} />
                           
          </div>
        </div>}
            <div className="ligne">
                <div id="editEtatmessageError" className="text-danger"></div>
            </div>
        </div >

        </Dialog >
    );
}
export default DlgPetEdit;