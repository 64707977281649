export class Pet {
    Age: number=0;
    DateLastVaccin: number=0;
    DateNaissance: number=0;
    HasSterilise: boolean=false;
    HasTraitement: boolean=false;
    IsDCD: boolean=false;
    Id: number=0;
    IdProprietaire: number=0;
    Identification: string="";
    InfoPension: string="";
    InfoProprio: string="";
    Nom: string="";
    NomProprietaire: string="";
    Particularite: string="";
    Race: string="";
    Sexe: number=0;
    Traitement: string="";
    Provenance: string="";
    Robe: string="";
    Type : number=0;
    TarificationId :number=0;
    IsVisible: boolean=false;
    //obso    
    Vaccin: string="";

    FullName:string="";
    Relation:number=0;
}


