import { Vaccine } from "../../common/models/vaccine";

export default function ConfigVaccinItem(props: {vaccin:Vaccine,onOpenDlgDelete:any,onOpenDlgEdit:any}) {
   

    const openDlgDelete = async () => {
        props.onOpenDlgDelete(props.vaccin);
    }

    const openDlgEdit = async () => {
        props.onOpenDlgEdit(props.vaccin);
    }
   

    return (
        <div className="d-flex line_box justify-content-between">
       <div className={`${props.vaccin.IsDelete===true? "text-danger" :""}`} >                
          <p>{props.vaccin.Libelle}</p>
        </div>
        <div className="col_action">
            <i className="pointer text-danger margright" onClick={openDlgDelete} title="Supprimer le vaccin"> <i className="fa-solid fa-remove" /></i>    
            <i className="pointer text-primary" onClick={openDlgEdit} title="Modifier le vaccin"> <i className="fa-solid fa-edit" /></i>
        </div>
      </div>);

};

